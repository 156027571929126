import React from "react";
import { Input, Table, Icon, Tooltip, Button, Row, Typography, Breadcrumb, message, Skeleton, Badge } from "antd";
import { Link } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import { UserInfo } from "../../Functions";

const { Search } = Input;
const { Text } = Typography;

const roles = ["admin", "school", "teacher", "user"];

const search_word = (keyword, origin) => {
  return origin.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
};


export class UserManagement extends React.Component {
  state = {
    disableDelete: true,
    selectedRowKeys: [],
    changeRole: 4,
    data: [],
    loading: true,
    results: [],
    role_id: 4,
    audits: 0
  };

  constructor(...props) {
    super(...props);
    const userInfo = new UserInfo();
    axios({
      method: 'post',
      url: '/api/users',
      data: {
        token: userInfo.getUserInfo().token
      }
    }).then(rsp => {
      this.setState({
        data: rsp.data.users,
        results: rsp.data.users,
      });
      axios({
        method: 'post',
        url: '/api/audit/teachers',
        data: {
          token: userInfo.getUserInfo().token
        }
      }).then(rsp => {
        this.setState({
          audits: rsp.data.audits.length
        });
        axios({
          method: 'post',
          url: '/api/token',
          data: {
            token: userInfo.getUserInfo().token
          }
        }).then(rsp => {
          if (rsp.data.user) {
            this.setState({
              role_id: rsp.data.user.role.id,
              loading: false
            });
          }
        });
      });
    });
  };

  handleChange = e => {
    const { data } = this.state;
    let keyword = e.target.value;
    if (keyword === "")
      this.setState({
        results: data
      });
    this.setState({
      keyword
    });
  };

  handleSearch = e => {
    const { data, keyword } = this.state;
    if (keyword.length === 0)
      return;
    let results = [];
    data.forEach(item => {
      if (search_word(keyword, item.name) || search_word(keyword, item.email))
        results.push(item);
    });
    this.setState({
      results
    });
  };

  deleteItems = () => {
    const { selectedRowKeys } = this.state;
    for (let i of selectedRowKeys) {
      axios({
        method: 'delete',
        url: '/api/user',
        params: {
          id: i
        }
      }).catch(err => {
        message.error("Error deleting user.");
        return false;
      });
    }
  };

  changeItems = () => {
    const { selectedRowKeys, data } = this.state;
    for (let i of selectedRowKeys) {
      data.forEach(item => {
        if (item.id === i) {
          axios({
            method: 'post',
            url: '/api/user/update/teacher',
            data: {
              id: item.id,
              role_id: 4,
              email: item.email
            }
          });
        }
      })

    }
  };

  changeRoles = () => {
    const { data, selectedRowKeys, changeRole } = this.state;

    data.forEach(item => {
      if (selectedRowKeys.indexOf(item.id) !== -1) item.role.id = changeRole;
    });
    this.setState({
      confirmChangeRole: false,
      disableDelete: true,
      selectedRowKeys: []
    }, () => message.success("Role changed successfully", 2));
  };

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys,
      disableDelete: selectedRowKeys.length === 0
    });
  };

  onRoleChange = value => {
    this.setState({
      changeRole: value
    });
  };

  deleteItem = id => {
    let data = this.state.data;
    let status = true;
    data.forEach((item, index) => {
      if (item.id === id) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You are deleting ' + item.name + '(' + item.email + ')',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Delete',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return axios({
              method: 'delete',
              url: '/api/user',
              params: {
                id: item.id
              }
            }).then(rsp => {
              return rsp.data.status;
            }).catch(err => {
              status = false;
              message.error("Error deleting user.");
            });
          }
        }).then(result => {
          if (result.value && status) {
            data.splice(index, 1);
            this.setState({
              data,
              results: data
            });
            message.success("Successfully deleted " + item.name + '(' + item.email + ')')
          }
        });
      }
    });
  };

  changeItem = id => {
    let data = this.state.data;
    data.forEach((item, index) => {
      if (item.id === id) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You are removing ' + item.name + '(' + item.email + ')',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Remove',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return axios({
              method: 'post',
              url: '/api/user/update/teacher',
              data: {
                id: item.id,
                role_id: 4,
                email: item.email
              }
            }).then(rsp => {
              return rsp.data.status;
            });
          }
        }).then(result => {
          if (result.value) {
            data.splice(index, 1);
            this.setState({
              data,
              results: data
            });
            message.success("Successfully removed " + item.name + '(' + item.email + ')')
          }
        });
      }
    });
  };

  render() {
    const { data, disableDelete, selectedRowKeys, loading, results, role_id, audits } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const selectedWords = () => {
      let char = [];
      data.forEach(item => {
        if (selectedRowKeys.indexOf(item.id) !== -1)
          char.push(item.name + '(' + item.email + ')');
      });
      return char;
    };

    const columns = [
      {
        title: 'Username',
        dataIndex: 'name',
      },
      {
        title: 'Role',
        dataIndex: 'role.id',
        render: text => (<Text>{roles[text - 1]}</Text>)
      },
      {
        title: 'Email',
        dataIndex: 'email',
      },
      {
        title: 'School',
        dataIndex: 'school.name',
        render: txt => txt ? txt : 'None'
      },
      {
        title: 'Action',
        render: (text, record) => (
          <span>
            {/*{role_id < 2 && (*/}
            {/*  [<Tooltip title="Edit">*/}
            {/*    <Link to={{*/}
            {/*      pathname: '/management/user/edit',*/}
            {/*      state: {*/}
            {/*        data: {*/}
            {/*          username: record.name,*/}
            {/*          email: record.email,*/}
            {/*          role_id: record.role.id*/}
            {/*        }*/}
            {/*      }*/}
            {/*    }}><Icon type="edit" /></Link>*/}
            {/*  </Tooltip>,*/}
            {/*    <Divider type="vertical" />]*/}
            {/*)}*/}
            <Tooltip title="Delete">
              {role_id === 2 ? <a onClick={() => this.changeItem(record.id)}><Icon type="delete" /></a> :
                <a onClick={() => this.deleteItem(record.id)}><Icon type="delete" /></a>}
            </Tooltip>
          </span>
        )
      }
    ];

    return (
      <Skeleton loading={loading} active>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/management">Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>User Management</Breadcrumb.Item>
        </Breadcrumb>
        <Search
          placeholder="Search"
          onSearch={this.handleSearch}
          onChange={this.handleChange}
          allowClear
          style={{ marginTop: 10 }}
        />
        <Row>
          {role_id === 2 && (
            <Link to='/management/user/audit'>
              <Badge count={audits} overflowCount={99}>
                <Button type="primary">Audit</Button>
              </Badge>
            </Link>
          )}
          {role_id < 2 && (<Link to='/management/user/add'><Button type="primary">Add User</Button></Link>)}
              {/*<Select*/}
              {/*  placeholder="Role to change"*/}
              {/*  style={{ width: 180, marginLeft: 20 }}*/}
              {/*  onChange={this.onRoleChange}*/}
              {/*>*/}
              {/*  {roles.map((item, index) => (*/}
              {/*    <Option value={index + 1} key={index}>{item}</Option>*/}
              {/*  ))}*/}
              {/*</Select>,*/}
              {/*<Button*/}
              {/*  type="primary"*/}
              {/*  disabled={disableDelete}*/}
              {/*  style={{ marginTop: 20 }}*/}
              {/*  onClick={() => {*/}
              {/*    Swal.fire({*/}
              {/*      title: 'Are you sure?',*/}
              {/*      text: 'You are changing roles of ' + selectedWords() + ' to ' + roles[changeRole - 1],*/}
              {/*      type: 'warning',*/}
              {/*      showCancelButton: true,*/}
              {/*      confirmButtonText: 'Change'*/}
              {/*    }).then(result => {*/}
              {/*      if (result.value) {*/}
              {/*        this.changeRoles();*/}
              {/*      }*/}
              {/*    });*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Change*/}
              {/*</Button>]*/}
          {role_id === 1 ? <Button
            type="danger"
            disabled={disableDelete}
            style={{ marginTop: 20, marginLeft: 10 }}
            onClick={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: 'You are deleting ' + selectedWords(),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Delete',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                  return this.deleteItems();
                }
              }).then(result => {
                if (result.value) {
                  let changedData = data.filter(item => selectedRowKeys.indexOf(item.id) === -1);
                  this.setState({
                    disableDelete: true,
                    selectedRowKeys: [],
                    data: changedData,
                    results: changedData
                  }, () => message.success("Successfully deleted", 2));
                }
              });
            }}
          >
            Delete
          </Button> :
            <Button
              type="danger"
              disabled={disableDelete}
              style={{ marginTop: 20, marginLeft: 10 }}
              onClick={() => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: 'You are deleting ' + selectedWords(),
                  type: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'Remove',
                  showLoaderOnConfirm: true,
                  preConfirm: () => {
                    return this.changeItems();
                  }
                }).then(result => {
                  if (result.value) {
                    let changedData = data.filter(item => selectedRowKeys.indexOf(item.id) === -1);
                    this.setState({
                      disableDelete: true,
                      selectedRowKeys: [],
                      data: changedData,
                      results: changedData
                    }, () => message.success("Successfully removed", 2));
                  }
                });
              }}
            >
              Delete
            </Button>}
        </Row>
        <Table columns={columns} dataSource={results} rowSelection={rowSelection}
               rowKey='id' style={{ background: '#fff', marginTop: 20, overflowX: "auto" }} />
      </Skeleton>
    )
  }
}
