import { Form, Input, Button, Typography, message } from 'antd';
import React from 'react';
import axios from 'axios';
import { UserInfo } from "../Functions";
import { Redirect } from "react-router-dom";

const { Title } = Typography;


class ChangePasswordForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    redirect: null,
    loading: false
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (values.old === values.password) {
          message.warning("Please use a different password!");
          return;
        }
        this.setState({
          loading: true
        });
        let token = new UserInfo().getUserInfo().token;
        axios({
          method: 'post',
          url: '/api/password',
          data: {
            token: token,
            old_pass: values.old,
            new_pass: values.password
          }
        }).then(rsp => {
          if (rsp.data.status) {
            message.success("Successfully changed password!");
            this.setState({
              loading: false,
              redirect: <Redirect to='/profile' />
            });
          }
          else {
            message.error("Please input right old password.");
            this.setState({
              loading: false
            })
          }
        });
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { redirect, loading } = this.state;

    return (
      <div style={{ background: "#fff", padding: 20 }}>
        <Title level={3}>Change Password</Title>
        {redirect}
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Old Password" hasFeedback>
            {getFieldDecorator('old', {
              rules: [
                {
                  required: true,
                  message: 'Please input your old password!',
                }
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="Password" hasFeedback>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your new password!',
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="Confirm Password" hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Change
          </Button>
          <Button onClick={() => window.history.back()} style={{ marginLeft: 20 }}>Cancel</Button>
        </Form>
      </div>
    );
  }
}

export const WrappedChangePasswordForm = Form.create({ name: 'change_password' })(ChangePasswordForm);
