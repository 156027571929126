import React from "react";
import { Input, Table, Icon, Tooltip, Breadcrumb, Skeleton, message, Divider } from "antd";
import { Link } from "react-router-dom";
import axios from 'axios';
import Clipboard from 'react-clipboard.js';
import { UserInfo } from "../Functions";

const { Search } = Input;
const InputGroup = Input.Group;

const search_word = (keyword, origin) => {
  return origin.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
};

export class All extends React.Component {
  category = this.props.location.pathname.split('/')[1] === 'school' ? 'school' : 'course';

  state = {
    data: [],
    results: [],
    keyword: '',
    loading: true,
    favorite: []
  };

  constructor(...props) {
    super(...props);
    const userInfo = new UserInfo();
    axios({
      method: 'get',
      url: '/api/schools'
    }).then(rsp => {
      let data = rsp.data.schools;
      data.forEach(item => {
        item.loading = false;
        item.favorite = false;
      });
      if (userInfo.getUserInfo()) {
        axios({
          method: 'post',
          url: '/api/token',
          data: {
            token: userInfo.getUserInfo().token
          }
        }).then(resp => {
          axios({
            method: 'get',
            url: '/api/favorite/school',
            params: {
              user_id: resp.data.user.id
            }
          }).then(school => {
            let favorite = school.data.schools;
            data.forEach(item => {
              item.favorite = userInfo.checkFavorite(favorite, item.id);
            });
            this.setState({
              favorite,
              data,
              results: data,
              loading: false
            });
          });
        });
      }
      else {
        this.setState({
          data,
          results: data,
          loading: false
        });
      }
    });
  };

  handleSearch = (e) => {
    const { data, keyword } = this.state;
    if (keyword.length === 0)
      return;
    let results = [];
    data.forEach(item => {
      if (search_word(keyword, item.name))
        results.push(item);
    });
    this.setState({
      results
    });
  };

  handleChange = e => {
    const { data } = this.state;
    let keyword = e.target.value;
    if (keyword === "")
      this.setState({
        results: data
      });
    this.setState({
      keyword
    });
  };

  render() {
    const { results, loading } = this.state;
    const userInfo = new UserInfo();

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text, record) => (<Link to={{
          pathname: '/school/detail/' + record.id,
          state: {
            referrer: this.props.location.pathname
          }
        }}>{text}</Link>)
      },
      {
        title: 'Location',
        dataIndex: 'location',
      },
      {
        title: 'Syllabuses',
        dataIndex: 'syllabuses.length',
      },
      {
        title: 'Action',
        render: (text, record) => (
          <div>
            <Tooltip title={record.favorite ? 'Remove from favorite' : 'Add to favorite'}>
              <a onClick={() => {
                let res = results;
                let data = this.state.data;
                if (!userInfo.getUserInfo()) {
                  message.warning("Please login to add to favorite!");
                  return;
                }
                res.forEach(item => {
                  if (item.id === record.id) {
                    item.loading = true;
                  }
                });
                this.setState({
                  results: res
                });
                axios({
                  method: 'post',
                  url: '/api/favorite',
                  data: {
                    token: userInfo.getUserInfo().token,
                    syllabus_id: 0,
                    school_id: record.id
                  }
                }).then(rsp => {
                  let favorite = this.state.favorite;
                  if (rsp.data.status) {
                    favorite.push(record);
                    message.success("Added " + record.name
                      + "(" + record.location + ') to favorite!');
                    res.forEach(item => {
                      if (item.id === record.id) {
                        item.loading = false;
                        item.favorite = true;
                      }
                    });
                    data.forEach(item => {
                      if (item.id === record.id) {
                        item.favorite = true;
                      }
                    });
                    this.setState({
                      favorite,
                      results: res,
                      data
                    });
                  }
                  else {
                    axios({
                      method: 'delete',
                      url: '/api/favorite',
                      data: {
                        token: userInfo.getUserInfo().token,
                        syllabus_id: 0,
                        school_id: record.id
                      }
                    }).then(response => {
                      favorite.forEach((item, index) => {
                        if (item.id === record.id) {
                          favorite.splice(index, 1);
                        }
                      });
                      res.forEach(item => {
                        if (item.id === record.id) {
                          item.favorite = false;
                          item.loading = false;
                        }
                      });
                      data.forEach(item => {
                        if (item.id === record.id) {
                          item.favorite = false;
                        }
                      });
                      this.setState({
                        favorite,
                        results: res,
                        data
                      });
                      message.success("You have removed this school from favorite");
                    });
                  }
                });
              }}>
                {record.loading ? <Icon type='loading' /> :
                  <Icon type="star" theme={record.favorite ? 'filled' : 'outlined'} />}
              </a>
            </Tooltip>
            <Divider type='vertical' />
            <Clipboard
              data-clipboard-text={"https://syllabus.drjchn.com/school/detail/" + record.id}
              component="a"
              onSuccess={() => message.success("School detail link has copied!")}
            >
              <Tooltip title="Copy Link"><Icon type="copy" /></Tooltip>
            </Clipboard>
          </div>
        )
      }
    ];

    return (
      <Skeleton active loading={loading}>
        <Breadcrumb>
          <Breadcrumb.Item><Link to='/'>Home</Link></Breadcrumb.Item>
          <Breadcrumb.Item>{this.category.substring(0, 1).toUpperCase() + this.category.substring(1)} List</Breadcrumb.Item>
        </Breadcrumb>
        <InputGroup compact>
          <Search
            placeholder="Search"
            onChange={this.handleChange}
            onSearch={this.handleSearch}
            allowClear
            style={{ marginTop: 10 }}
          />
        </InputGroup>
        <Table columns={columns} dataSource={results}
               rowKey='id' style={{ background: '#fff', marginTop: 20, overflowX: "auto" }} />
      </Skeleton>
    )
  }
}
