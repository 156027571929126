import React from 'react';
import './App.css';
import { PageHeader, Icon, Menu, Avatar, Badge, Dropdown, Layout, message, Typography } from "antd";
import * as View from './Views';
import { Route, Switch, Link, withRouter, Redirect } from "react-router-dom";
import axios from 'axios';
import { UserInfo } from "./Views";

const { Content, Sider } = Layout;
const { Text } = Typography;

const Mask = props => (
  <div
    style={{
      background: "rgba(0, 0, 0, 0.6)",
      position: "fixed",
      height: "100%",
      width: "100%",
      zIndex: 200,
      display: props.display
    }}
    onClick={props.onClick}
  ></div>
);

/* Main Frame of website */
class App extends React.Component {
  state = {
    user: null,
    redirect: null,
    contentWidth: null,
    contentHeight: null,
    display: 'none',
    collapsed: true,
    dot: false,
    avatar: ''
  };

  componentDidMount() {
    this.updateSize();
    window.addEventListener('resize', () => this.updateSize());
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateSize());
  }

  updateSize = () => {
    let height = window.innerHeight;
    let width = window.innerWidth;
    height = width > 576 ? height - 80 : height - 124;
    let display = width > 991 ? 'none' : this.state.display;
    let collapsed = width <= 991 ? this.state.collapsed : false;
    width = width > 991 ? width - 200 : width;
    this.setState({
      contentHeight: height,
      contentWidth: width,
      display: display,
      collapsed: collapsed
    });
  };

  constructor(...props) {
    super(...props);
    const userInfo = new UserInfo();
    let info = userInfo.getUserInfo();
    if (info && info !== -1) {
      axios({
        method: 'post',
        url: '/api/token',
        data: {
          token: info.token
        }
      }).then(rsp => {
        if (rsp.data.user) {
          let user = {
            username: rsp.data.user.name,
            role_id: rsp.data.user.role.id
          };
          this.setState({
            user
          });
          axios({
            method: 'post',
            url: '/api/user/avatar',
            data: {
              token: userInfo.getUserInfo().token
            }
          }).then(response => {
            if (response.data) {
              this.setState({
                avatar: '/static/img/avatar/' + response.data
              });
            }
          });
          if (rsp.data.user.role.id === 1) {
            axios({
              method: 'get',
              url: '/api/audits',
            }).then(response => {
              this.setState({
                dot: response.data.audits.length > 0
              });
            });
          }
          else if (rsp.data.user.role.id === 2) {
            axios({
              method: 'post',
              url: '/api/audit/teachers',
              data: {
                token: new UserInfo().getUserInfo().token
              }
            }).then(response => {
              this.setState({
                dot: response.data.audits.length > 0
              });
            });
          }
        } else {
          message.error("User Authentication failed");
          window.localStorage.removeItem('userEncrypted');
          this.setState({
            user: null,
            redirect: <Redirect to='/' />,
            avatar: '',
            dot: false
          });
        }
      });
    }
    this.props.history.listen(() => {
      let session = userInfo.getUserInfo();
      if (!session) {
        this.setState({
          user: null,
          redirect: null
        });
        return;
      } else if (session === -1) {
        message.error("User session expired");
        this.setState({
          user: null,
          redirect: null,
          avatar: '',
          dot: false
        });
        return;
      }
      axios({
        method: 'post',
        url: '/api/token',
        data: {
          token: session.token
        }
      }).then(rsp => {
        if (rsp.data.user) {
          let user = {
            username: rsp.data.user.name,
            role_id: rsp.data.user.role.id
          };
          this.setState({
            user,
            redirect: null
          });
          axios({
            method: 'post',
            url: '/api/user/avatar',
            data: {
              token: userInfo.getUserInfo().token
            }
          }).then(response => {
            if (response.data) {
              this.setState({
                avatar: '/static/img/avatar/' + response.data
              });
            }
          });
          if (rsp.data.user.role.id === 1) {
            axios({
              method: 'get',
              url: '/api/audits',
            }).then(response => {
              this.setState({
                dot: response.data.audits.length > 0
              });
            });
          }
          if (rsp.data.user.role.id === 2) {
            axios({
              method: 'post',
              url: '/api/audit/teachers',
              data: {
                token: new UserInfo().getUserInfo().token
              }
            }).then(response => {
              this.setState({
                dot: response.data.audits.length > 0
              });
            });
          }
        } else {
          message.error("User Authentication failed");
          window.localStorage.removeItem('userEncrypted');
          this.setState({
            user: null,
            dot: false,
            redirect: <Redirect to='/' />,
            avatar: ''
          });
        }
      });
    });
  }

  render() {
    const { user, redirect, contentHeight, contentWidth, display, collapsed, dot, avatar } = this.state;
    /* Menu for Avatar on the top right corner */
    const AvatarMenu = (status) => {
      return status ? (
        <Menu>
          <Menu.Item>
            <strong >{user.username}</strong>
          </Menu.Item>
          <Menu.Item>
            <Link to="/profile">
              <Icon type="user" /> Profile
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Text type='link' onClick={() => {
              const userInfo = new UserInfo();
              axios({
                method: 'post',
                url: '/api/logout',
                data: {
                  token: userInfo.getUserInfo().token
                }
              }).then(rsp => {
                if (rsp.data) {
                  window.localStorage.removeItem('userEncrypted');
                  this.setState({
                    user: null,
                    avatar: '',
                    redirect: <Redirect to='/' />
                  }, () => {
                    message.success("Logout succeeded");
                    setTimeout(() => window.location.reload(), 1000);
                  });
                }
              });
            }}>
              <Icon type="logout" /> Logout
            </Text>
          </Menu.Item>
        </Menu>
      ) : (
        <Menu>
          <Menu.Item>
            <Link to="/login">
              <Icon type="login" /> Login
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to="/register">
              <Icon type="form" /> Register
            </Link>
          </Menu.Item>
        </Menu>
      );
    };

    /**
     * Dropdown Menu on avatar
     * @returns DropdownMenu
     */
    const DropdownMenu = () => {
      return (
        <Dropdown overlay={AvatarMenu(user)} key="avatar" trigger={['click', 'hover']}>
          <Avatar icon="user" src={avatar} />
        </Dropdown>
      )
    };

    return (
      <Layout>
        {/*Header Part Start */}
        <PageHeader
          title={<Link to='/' style={{ color: 'black' }}>CS Syllabus Repo</Link>}
          style={{
            background: '#fff',
          }}
          extra={[
            <DropdownMenu key="avatar" />,
          ]}
        />
        {/*Header Part End */}

        {/*Main Body Start */}
        <Layout>
          {/*Sider Part Start */}
          <Sider
            breakpoint="lg"
            collapsedWidth="0"
            theme='light'
            collapsed={collapsed}
            onCollapse={collapsed => {
              this.setState({
                display: !collapsed && window.innerWidth < 992 ?  'block' : 'none',
                collapsed: collapsed
              });
            }}
            style={{
              zIndex: 300
            }}
          >
            <Menu
              selectedKeys={[]}
              mode="inline"
            >
              <Menu.Item key="home">
                <Link to='/'>
                  <Icon type="home" />
                  <span>Home</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="search">
                <Link to='/search'>
                  <Icon type="search" />
                  <span>Search</span>
                </Link>
              </Menu.Item>
              <Menu.Item key="compare">
                <Link to='/compare'>
                  <Icon type="block" />
                  <span>Compare</span>
                </Link>
              </Menu.Item>
              {user && (
                <Menu.Item key="profile">
                  <Link to='/profile'>
                    <Icon type="user" />
                    <span>Profile</span>
                  </Link>
                </Menu.Item>
              )}
              {user && user.role_id < 4 && (
                <Menu.Item key="management">
                  <Link to='/management'>
                    <Badge dot={dot}>
                      <Icon type="setting" />
                      <span>Management</span>
                    </Badge>
                  </Link>
                </Menu.Item>
              )}
            </Menu>
          </Sider>
          {/*Sider Part End*/}

          {/*Content Part Start*/}
          <Layout>
            <Mask display={display} onClick={() => {
              this.setState({
                collapsed: !collapsed,
                display: 'none'
              })
            }} />
            {/*Edit page redirect here*/}
            <Content style={{
              padding: 24,
              overflowX: "hidden",
              minWidth: contentWidth,
              maxHeight: contentHeight,
              zIndex: 100
            }}>
              {redirect}
              {/*HomePage*/}
              <Switch>
                <Route exact path='/' component={View.Home} />
                <Route exact path='/login' component={View.WrappedLoginForm} />
                <Route exact path='/register' component={View.WrappedRegistrationForm} />
                <Route exact path='/reset/:token' component={View.WrappedResetPasswordForm} />
                <Route exact path='/forget' component={View.WrappedForgetPasswordForm} />

                {/*Compare Page*/}
                <Route exact path='/compare' component={View.Compare} />

                {/*Search page*/}
                <Route exact path='/search' component={View.SearchPage} />

                {/*Information Pages*/}
                {/*<Route exact path='/course/index/:name' component={View.CourseIndex} />*/}
                {/*<Switch>*/}
                <Route exact path='/school' component={View.All} />
                {/*<Route exact path='/course' component={View.All} />*/}
                {/*</Switch>*/}

                {/*Detail page*/}
                <Route exact path={'/school/detail/:id'} component={View.SchoolDetail} />
                <Route path={'/course/detail/:id'} component={View.CourseDetail} />

                {user && (
                  <div>
                    {/*Profile pages*/}
                    <Route exact path='/profile' component={View.Profile} />
                    <Route exact path='/profile/edit' component={View.WrappedEditProfileForm} />
                    <Route exact path='/profile/change_password' component={View.WrappedChangePasswordForm} />
                    {user.role_id === 4 && ([
                      <Route exact path='/profile/school' component={View.WrappedNewSchoolForm} />,
                      <Route exact path='/profile/teacher' component={View.WrappedNewTeacherForm} />
                    ])}
                    {user.role_id < 4 && (
                      <div>
                        {/*Management page*/}
                        <Route exact path='/management' component={View.Management} />
                        {/*Syllabus Management*/}
                        <Route exact path='/management/syllabus' component={View.SyllabusManagement} />
                        <Route exact path='/management/syllabus/add' component={View.WrappedNewSyllabusForm} />
                        <Route exact path='/management/syllabus/edit' component={View.WrappedEditSyllabusForm} />

                        {user.role_id < 3 && (
                          <div>
                            {/*User Management*/}
                            <Route exact path='/management/user' component={View.UserManagement} />
                            <Route exact path='/management/user/add' component={View.WrappedAddUserForm} />
                            {/*<Route exact path='/management/user/edit' component={View.WrappedEditUserForm} />*/}
                            {user.role_id === 2 && ([
                              <Route exact path='/management/user/audit' component={View.AuditTeacher} />,
                              <Route exact path='/profile/update_school' component={View.WrappedEditSchoolForm} />
                            ])}

                            {user.role_id < 2 && ([
                              <Route exact path='/management/school' component={View.SchoolManagement} />,
                              <Route exact path='/management/school/audit' component={View.AuditSchool} />
                            ])}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <Route component={View.NotFound} />
              </Switch>
            </Content>
          </Layout>
          {/*Content Part End */}
        </Layout>
        {/*Main Body End */}
      </Layout>
    )
  }
}

export default withRouter(App);
