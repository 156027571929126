import { Form, Input, Icon, Button, Upload, Typography, Breadcrumb, message } from 'antd';
import React from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { UserInfo } from "../../Functions";

const { Title } = Typography;
const { TextArea } = Input;


class NewSchoolForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    redirect: null,
    fileList: [],
    filename: '',
    user: null,
    loading: false
  };

  constructor(...props) {
    super(...props);
    axios({
      method: 'post',
      url: '/api/token',
      data: {
        token: new UserInfo().getUserInfo().token
      }
    }).then(rsp => {
      this.setState({
        user: rsp.data.user
      });
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        const userInfo = new UserInfo();
        axios({
          method: 'post',
          url: '/api/audit',
          data: {
            school_name: values.school_name,
            introduction: values.introduction,
            description: this.state.filename,
            location: values.location,
            token: userInfo.getUserInfo().token
          }
        }).then(rsp => {
          if (rsp.data.status) {
            message.success("You have successfully submitted application for school!");
            this.setState({
              redirect: <Redirect to='/profile' />,
              loading: false
            });
          } else {
            message.error("Do not submit application twice!");
            this.setState({ loading: false });
          }
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { redirect, fileList, user, loading } = this.state;

    return (
      <div style={{ background: "#fff", padding: 20 }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/profile">Profile</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>School</Breadcrumb.Item>
        </Breadcrumb>
        {redirect}
        <Title level={3}>New School</Title>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="School Name">
            {getFieldDecorator('school_name', {
              rules: [
                {
                  required: true,
                  message: 'Please input school name',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Location" hasFeedback>
            {getFieldDecorator('location', {
              rules: [
                {
                  required: true,
                  message: 'Please input the country of the school',
                }
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label='Introduction'>
            {getFieldDecorator('introduction', {
              rules: [{ required: true, message: 'Please input introduction of school', whitespace: true }],
            })(<TextArea autosize placeholder='Introduction of your school' />)}
          </Form.Item>
          <Form.Item label='Upload'>
          <Upload
            name="logo"
            customRequest={options => {
              const { onSuccess, file, onProgress } = options;
              let formData = new FormData();
              formData.append("file", file);
              formData.append("category", "school");
              formData.append("user", user.name);
              axios({
                method: 'post',
                url: '/api/school/upload',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: e => {
                  onProgress({ percent: (e.loaded / e.total) * 100 });
                }
              }).then(rsp => {
                let filename = rsp.data.name;
                this.setState({
                  filename
                });
                onSuccess(rsp.data);
              });
            }}
            fileList={fileList}
            onChange={info => {
              let fileList = [...info.fileList];
              fileList = fileList.slice(-1);

              fileList = fileList.map(file => {
                if (file.response) {
                  // Component will show file.url as link
                  file.url = file.response.url;
                }
                return file;
              });

              this.setState({ fileList });
            }}
          >
            <Button>
              <Icon type="upload" /> Click to upload
            </Button>
          </Upload>
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>Apply</Button>
          <Button onClick={() => window.history.back()} style={{ marginLeft: 20 }}>Cancel</Button>
        </Form>
      </div>
    );
  }
}

export const WrappedNewSchoolForm = Form.create({ name: 'new_school' })(NewSchoolForm);
