import React from 'react';
import { Button, Icon, Tooltip, Typography, Breadcrumb, Table, Input, Skeleton, message } from "antd";
import { Link } from "react-router-dom";
import axios from 'axios';
import Clipboard from 'react-clipboard.js';
import { UserInfo } from "../Functions";

const { Title, Paragraph } = Typography;
const InputGroup = Input.Group;
const { Search } = Input;

const search_word = (keyword, origin) => {
  return origin.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
};

export class SchoolDetail extends React.Component {
  state = {
    data: {
      syllabuses: []
    },
    keyword: "",
    syllabuses: [],
    loading: [true, false],
    favorite: false
  };

  switchView = (pathname) => {
    let path = pathname.split('/')[1];
    switch (path) {
      case '':
        return '';
      case 'profile':
        return (<Breadcrumb.Item><Link to='/profile'>Profile</Link></Breadcrumb.Item>);
      case 'management':
        return (<span>
          <Breadcrumb.Item><Link to='/management'>Management</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to='/management/school'>School Management</Link></Breadcrumb.Item>
        </span>);
      default:
        return (<Breadcrumb.Item><Link to='/school'>School List</Link></Breadcrumb.Item>);
    }
  };

  constructor(...props) {
    super(...props);
    const school_id = this.props.match.params.id;
    let loading = this.state.loading;
    const userInfo = new UserInfo();
    axios({
      method: 'get',
      url: '/api/school',
      params: {
        id: school_id
      }
    }).then(rsp => {
      this.setState({
        data: rsp.data.school,
        syllabuses: rsp.data.school.syllabuses,
      });
      if (userInfo.getUserInfo()) {
        axios({
          method: 'post',
          url: '/api/token',
          data: {
            token: userInfo.getUserInfo().token
          }
        }).then(response => {
          axios({
            method: 'get',
            url: '/api/favorite/school',
            params: {
              user_id: response.data.user.id
            }
          }).then(resp => {
            loading[0] = false;
            this.setState({
              loading,
              favorite: userInfo.checkFavorite(resp.data.schools, this.state.data.id)
            })
          });
        });
      }
      else {
        loading[0] = false;
        this.setState({
          loading
        })
      }
    });
  };

  handleSearch = e => {
    const { data, keyword } = this.state;
    if (keyword.length === 0)
      return;
    let syllabuses = [];
    data.syllabuses.forEach(item => {
      if (search_word(keyword, item.title))
        syllabuses.push(item);
    });
    this.setState({
      syllabuses
    });
  };

  handleChange = e => {
    const { data } = this.state;
    let keyword = e.target.value;
    if (keyword === "")
      this.setState({
        syllabuses: data['syllabuses']
      });
    this.setState({
      keyword
    });
  };

  render() {
    const { data, syllabuses, loading, favorite } = this.state;
    const columns = [
      {
        title: 'Syllabus',
        dataIndex: 'title',
        key: 'title',
        render: (text, record) => (<Link to={{
          pathname: '/course/detail/' + record.id,
          state: {
            referrer: this.props.location.pathname
          }
        }}>{text}</Link>)
      }
    ];

    return (
      <Skeleton loading={loading[0]} active>
        <div style={{ background: '#fff', padding: 20 }}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to='/'>Home</Link></Breadcrumb.Item>
            {this.props.location.state && this.switchView(this.props.location.state.referrer)}
            <Breadcrumb.Item>{data.name}</Breadcrumb.Item>
          </Breadcrumb>
          <Title>{data.name}</Title>
          <div style={{ float: "right" }}>
            <Tooltip title={favorite ? 'Remove from favorite' : 'Add to favorite'}>
              <Button
                style={{ marginRight: 10 }}
                onClick={() => {
                  const userInfo = new UserInfo();
                  let load = this.state.loading;
                  if (!userInfo.getUserInfo()) {
                    message.warning("Please login to add to favorite!");
                    return;
                  }
                  load[1] = true;
                  this.setState({
                    loading: load
                  });
                  axios({
                    method: 'post',
                    url: '/api/favorite',
                    data: {
                      token: userInfo.getUserInfo().token,
                      syllabus_id: 0,
                      school_id: data.id
                    }
                  }).then(rsp => {
                    if (rsp.data.status) {
                      load[1] = false;
                      this.setState({
                        loading: load
                      });
                      message.success("Added " + data.name
                        + "(" + data.location + ') to favorite!');
                    }
                    else {
                      axios({
                        method: 'delete',
                        url: '/api/favorite',
                        data: {
                          token: userInfo.getUserInfo().token,
                          syllabus_id: data.id,
                          school_id: 0
                        }
                      }).then(rsp => {
                        load[1] = false;
                        this.setState({
                          loading: load
                        });
                        message.warning("You have added this school to favorite");
                      });
                    }
                    this.setState({
                      favorite: !favorite
                    });
                  });
                }}
              >
                {loading[1] ? <Icon type='loading' /> : <Icon type="star" theme={favorite ? 'filled' : 'outlined'} /> }
              </Button>
            </Tooltip>
            <Clipboard
              data-clipboard-text={"https://syllabus.drjchn.com/school/detail/" + data.id}
              component="a"
              onSuccess={() => message.success("School detail link has copied!")}
            >
              <Tooltip title="Copy Link">
                <Button><Icon type="copy" /></Button>
              </Tooltip>
            </Clipboard>
          </div>
          <Title level={2}>Introduction</Title>
          <Paragraph>{data.introduction}</Paragraph>
          <Title level={2}>Course List</Title>
          <InputGroup compact>
            <Search
              placeholder="Search"
              onChange={this.handleChange}
              onSearch={this.handleSearch}
              allowClear
            />
          </InputGroup>
          <Table columns={columns} dataSource={syllabuses}
                 rowKey='id' style={{ background: '#fff', marginTop: 20 }} />
        </div>
      </Skeleton>
    );
  }
}
