import { Form, Input, Tooltip, Icon, Select, Button, Typography, Breadcrumb, message } from 'antd';
import React from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

const { Option } = Select;
const { Title } = Typography;


class AddUserForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    loading: false,
    redirect: null
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        axios({
          method: 'post',
          url: '/api/register',
          data: {
            name: values.name,
            password: values.password,
            email: values.email,
            role_id: values.role_id
          }
        }).then(rsp => {
          if (rsp.data.status) {
            message.success("New user have successfully added!");
            this.setState({
              redirect: <Redirect to='/' />
            });
          }
          else {
            message.error("The username or email has been registered!", 5);
          }
          this.setState({
            loading: false
          });
        });
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, redirect } = this.state;

    return (
      <div style={{ background: "#fff", padding: 20 }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/management">Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/management/user">User Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>New</Breadcrumb.Item>
        </Breadcrumb>
        {redirect}
        <Title level={3}>Add User</Title>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="E-mail">
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Password" hasFeedback>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your password!',
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="Confirm Password" hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </Form.Item>
          <Form.Item
            label={
              <span>
              Username&nbsp;
                <Tooltip title="What do you want others to call you?">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
            }
          >
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please input your nickname!', whitespace: true }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Role">
            {getFieldDecorator('role_id', {
              rules: [
                { required: true, message: "Please select user's role!" },
              ],
            })(<Select>
              <Option value={1}>admin</Option>
              <Option value={2}>school</Option>
              <Option value={3}>teacher</Option>
              <Option value={4}>user</Option>
            </Select>)}
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>Add</Button>
          <Button onClick={() => window.history.back()} style={{ marginLeft: 20 }}>Cancel</Button>
        </Form>
      </div>
    );
  }
}

export const WrappedAddUserForm = Form.create({ name: 'add_user' })(AddUserForm);
