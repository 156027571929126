import React from "react";
import { Input, Table, Icon, Divider, Tooltip, Button,
  Row, Badge, Breadcrumb, message, Skeleton } from "antd";
import { Link } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';

const { Search } = Input;
const InputGroup = Input.Group;

const search_word = (keyword, origin) => {
  return origin.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
};

export class SchoolManagement extends React.Component {
  state = {
    disableDelete: true,
    selectedRowKeys: [],
    data: [],
    loading: true,
    results: [],
    audits: 0
  };

  constructor(...props) {
    super(...props);
    axios({
      method: 'get',
      url: '/api/audits'
    }).then(rsp => {
      this.setState({
        audits: rsp.data.audits.length
      });
      axios({
        method: 'get',
        url: '/api/schools'
      }).then(rsp => {
        this.setState({
          data: rsp.data.schools,
          results: rsp.data.schools,
          loading: false
        });
      });
    });
  }

  deleteItems = () => {
    const { selectedRowKeys } = this.state;
    for (let i of selectedRowKeys) {
      axios({
        method: 'delete',
        url: '/api/school',
        params: {
          id: i
        }
      }).then(rsp => {
        return rsp.data.status;
      }).catch(err => {
        message.error("There are still users in the school, delete failed.");
        return false;
      });
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys,
      disableDelete: selectedRowKeys.length === 0
    });
  };

  handleChange = e => {
    const { data } = this.state;
    let keyword = e.target.value;
    if (keyword === "")
      this.setState({
        results: data
      });
    this.setState({
      keyword
    });
  };

  handleSearch = e => {
    const { data, keyword } = this.state;
    if (keyword.length === 0)
      return;
    let results = [];
    data.forEach(item => {
      if (search_word(keyword, item.name))
        results.push(item);
    });
    this.setState({
      results
    });
  };

  deleteItem = id => {
    let data = this.state.data;
    let status = true;
    data.forEach((item, index) => {
      if (item.id === id) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You are deleting ' + item.name + '(' + item.location + ')',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Delete',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return axios({
              method: 'delete',
              url: '/api/school',
              params: {
                id: item.id
              }
            }).then(rsp => {
              return rsp.data.status;
            }).catch(err => {
              status = false;
              message.error("There are still users in the school, delete failed.");
            });
          }
        }).then(result => {
          if (status && result.value) {
            data.splice(index, 1);
            this.setState({
              data,
              results: data
            });
            message.success("Successfully deleted " + item.name + '(' + item.location + ')');
          }
        });
      }
    });
  };

  render() {
    const { data, disableDelete, selectedRowKeys, loading, results, audits } = this.state;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (<Link to={{
          pathname: '/school/detail/' + record.id,
          state: {
            referrer: this.props.location.pathname
          }
        }}>{text}</Link>)
      },
      {
        title: 'Location',
        dataIndex: 'location',
        key: 'location'
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
        {/*<Tooltip title="Edit">*/}
        {/*  <Link to={{*/}
        {/*    pathname: '/management/school/edit',*/}
        {/*    state: {*/}
        {/*      data: {*/}
        {/*        name: record.name,*/}
        {/*        location: record.location,*/}
        {/*        introduction: record.introduction*/}
        {/*      }*/}
        {/*    }*/}
        {/*  }}><Icon type="edit" /></Link>*/}
        {/*</Tooltip>*/}
        <Divider type="vertical" />
        <Tooltip title="Delete">
          <a onClick={() => this.deleteItem(record.id)}><Icon type="delete" /></a>
        </Tooltip>
      </span>
        )
      }
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const selectedWords = () => {
      let char = [];
      data.forEach(item => {
        if (selectedRowKeys.indexOf(item.id) !== -1)
          char.push(item.name + '(' + item.location + ')');
      });
      return char;
    };

    return (
      <Skeleton active loading={loading}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/management">Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>School Management</Breadcrumb.Item>
        </Breadcrumb>
        <InputGroup compact>
          <Search
            placeholder="Search"
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            allowClear
            style={{ marginTop: 10 }}
          />
        </InputGroup>
        <Row>
          <Link to='/management/school/audit'>
            <Badge count={audits} overflowCount={99}>
              <Button type="primary">Audit</Button>
            </Badge>
          </Link>
          <Button type="danger" disabled={disableDelete}
                  style={{ marginTop: 20, marginLeft: 10 }}
                  onClick={() => {
                    Swal.fire({
                      title: 'Are you sure?',
                      text: 'You are deleting ' + selectedWords(),
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'Delete',
                      showLoaderOnConfirm: true,
                      preConfirm: () => {
                        return this.deleteItems();
                      }
                    }).then(results => {
                      if (results.value) {
                        let changedData = data.filter(item => selectedRowKeys.indexOf(item.id) === -1);
                        this.setState({
                          disableDelete: true,
                          selectedRowKeys: [],
                          data: changedData,
                          results: changedData
                        }, () => message.success("Successfully deleted", 2));
                      }
                    });
                  }}>
            Delete
          </Button>
        </Row>
          <Table columns={columns} dataSource={results} rowSelection={rowSelection}
                 rowKey='id' style={{ background: '#fff', marginTop: 20, overflowX: "auto" }} />
      </Skeleton>
    )
  }
}
