import React from "react";
import { Breadcrumb, Input, InputNumber, Form, Icon, Button, Select, Typography, message } from "antd";
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const Bread = () => (
  <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">Home</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <Link to="/management">Management</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <Link to="/management/syllabus">Syllabus Management</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>New</Breadcrumb.Item>
  </Breadcrumb>
);

const getCorrespondPilos = (data, i) => {
  let result = [];
  for (let pilo_cilo of data.cilos[i].pilo_cilos) {
    data.pilos.forEach(item => {
      if (item.id === pilo_cilo.pilo_id)
        result.push((item.pilo_id - 1).toString());
    });
  }
  return result;
};

const getCorrespondCilos = (data, i) => {
  let result = [];
  for (let ass_cilo of data.assessments[i].assessment_cilos) {
    data.cilos.forEach(item => {
      if (item.id === ass_cilo.cilo_id)
        result.push((item.cilo_id - 1).toString());
    });
  }
  return result;
};

let id = {
  contents: 0,
  pilos: 0,
  cilos: 0,
  assessments: 0,
  textBooks: 0
};

const initArr = (length, category) => {
  let arr = [];
  for (let i = 0; i < length; i++) {
    arr.push(i);
    id[category]++;
  }
  return arr;
};

class EditSyllabusForm extends React.Component {
  state = {
    data: this.props.location.state.data,
    loading: false,
    redirect: null
  };

  remove = (k, component) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue(component);
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }
    id[component]--;
    // can use data-binding to set
    form.setFieldsValue({
      [component]: keys.filter(key => key !== k),
    });
  };

  add = component => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue(component);
    const nextKeys = keys.concat(id[component]++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      [component]: nextKeys,
    });
  };

  handleSubmit = e => {
    const { data } = this.state;
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        axios({
          method: 'post',
          url: '/api/syllabus/update',
          data: {
            id: data.id,
            school_id: data.school.id,
            code: values.code,
            title: values.title,
            prepare: values.prepare,
            review: values.review,
            unit: values.unit,
            hours: values.hours,
            pre_req: values.pre_req,
            co_req: values.co_req,
            offer_unit: values.offer_unit,
            aim: values.aim
          }
        }).then(async rsp => {
          if (rsp.data.status) {
            for (let i = 0; i < values.contents.length; i++) {
              if (i < data.contents.length) {
                axios({
                  method: 'post',
                  url: '/api/content/update',
                  data: {
                    id: data.contents[i].id,
                    content_id: i + 1,
                    syllabus_id: data.id,
                    title: values.content[i].title,
                    hours: values.content[i].hours,
                    content: values.content[i].content
                  }
                });
              } else if (values.content.length > 0) {
                values.content.forEach((item, index) => {
                  item.syllabus_id = data.id;
                  item.content_id = index + 1;
                });
                axios({
                  method: 'post',
                  url: '/api/content',
                  data: {
                    contents: values.content.slice(data.contents.length, values.content.length)
                  }
                });
                break;
              }
            }
            if (values.contents.length < data.contents.length) {
              for (let i = values.contents.length; i < data.contents.length; i++) {
                axios({
                  method: 'delete',
                  url: '/api/content',
                  params: {
                    id: data.contents[i].id
                  }
                });
              }
            }
            for (let i = 0; i < values.textBooks.length; i++) {
              if (i < data.textBooks.length) {
                axios({
                  method: 'post',
                  url: '/api/textBook/update',
                  data: {
                    id: data.textBooks[i].id,
                    syllabus_id: data.id,
                    year: values.textBook[i].year,
                    author: values.textBook[i].author,
                    title: values.textBook[i].title
                  }
                });
              } else if (values.textBook.length > 0) {
                values.textBook.forEach((item, index) => {
                  item.syllabus_id = data.id;
                });
                axios({
                  method: 'post',
                  url: '/api/textBook',
                  data: {
                    textBooks: values.textBook.slice(data.textBooks.length, values.textBook.length)
                  }
                });
                break;
              }
            }
            if (values.textBooks.length < data.textBooks.length) {
              for (let i = values.textBooks.length; i < data.textBooks.length; i++) {
                axios({
                  method: 'delete',
                  url: '/api/textBook',
                  params: {
                    id: data.textBooks[i].id
                  }
                });
              }
            }
            let pilo_ids = [];
            let cilo_ids = [];
            let assessment_ids = [];
            for (let i = 0; i < values.pilos.length; i++) {
              if (i < data.pilos.length) {
                pilo_ids.push(data.pilos[i].id);
                axios({
                  method: 'post',
                  url: '/api/pilo/update',
                  data: {
                    id: data.pilos[i].id,
                    syllabus_id: data.id,
                    pilo_id: i + 1,
                    content: values.pilo[i].content
                  }
                });
              } else if (values.pilo.length > 0) {
                values.pilo.forEach((item, index) => {
                  item.syllabus_id = data.id;
                  item.pilo_id = index + 1;
                });
                await axios({
                  method: 'post',
                  url: '/api/pilo',
                  data: {
                    pilos: values.pilo.slice(data.pilos.length, values.pilo.length)
                  }
                }).then(rsp => {
                    for (let id of rsp.data.ID) {
                      pilo_ids.push(id);
                    }
                });
              }
            }
            for (let i = 0; i < values.cilos.length; i++) {
              if (i < data.cilos.length) {
                cilo_ids.push(data.cilos[i].id);
                axios({
                  method: 'post',
                  url: '/api/cilo/update',
                  data: {
                    id: data.cilos[i].id,
                    syllabus_id: data.id,
                    cilo_id: i + 1,
                    content: values.cilo[i].content
                  }
                });
              } else if (values.cilo.length > 0) {
                values.cilo.forEach((item, index) => {
                  item.syllabus_id = data.id;
                  item.cilo_id = index + 1
                });
                await axios({
                  method: 'post',
                  url: '/api/cilo',
                  data: {
                    cilos: values.cilo.slice(data.cilos.length, values.cilo.length)
                  }
                }).then(rsp => {
                  for (let id of rsp.data.ID) {
                    cilo_ids.push(id);
                  }
                });
              }
            }
            for (let i = 0; i < values.cilos.length; i++) {
              if (i < data.cilos.length) {
                axios({
                  method: 'post',
                  url: '/api/tla/update',
                  data: {
                    id: data.cilos[i].tla.id,
                    cilo_id: cilo_ids[i],
                    content: values.tla[i].content
                  }
                });
              } else if (values.tla.length > 0) {
                values.tla.forEach((item, index) => {
                  item.cilo_id = cilo_ids[i + index];
                });
                axios({
                  method: 'post',
                  url: '/api/tla',
                  data: {
                    tlas: values.tla.slice(data.cilos.length, values.tla.length)
                  }
                });
              }
            }
            if (values.tla.length < data.cilos.length) {
              for (let i = values.cilos.length; i < data.cilos.length; i++) {
                axios({
                  method: 'delete',
                  url: '/api/tla',
                  params: {
                    id: data.cilos[i].tla.id
                  }
                });
              }
            }
            for (let i = 0; i < values.assessments.length; i++) {
              if (i < data.assessments.length) {
                assessment_ids.push(data.assessments[i].id);
                axios({
                  method: 'post',
                  url: '/api/assessment/update',
                  data: {
                    id: data.assessments[i].id,
                    syllabus_id: data.id,
                    method: values.assessment[i].method,
                    weighting: values.assessment[i].weighting,
                    description: values.assessment[i].description
                  }
                });
              } else if (values.assessment.length > 0) {
                values.assessment.forEach(item => {
                  item.syllabus_id = data.id;
                });
                await axios({
                  method: 'post',
                  url: '/api/assessment',
                  data: {
                    assessments: values.assessment.slice(data.assessments.length, values.assessment.length)
                  }
                }).then(rsp => {
                  for (let id of rsp.data.ID) {
                    assessment_ids.push(id);
                  }
                });
              }
            }
            let old_pilo_cilos = [];
            let pilo_cilos = [];
            let old_ass_cilos = [];
            let ass_cilos = [];
            data.cilos.forEach(item => {
              for (let pilo_cilo of item.pilo_cilos) {
                old_pilo_cilos.push(pilo_cilo);
              }
            });
            data.assessments.forEach(item => {
              for (let ass_cilo of item.assessment_cilos) {
                old_ass_cilos.push(ass_cilo);
              }
            });
           values.cilo.forEach((item, index) => {
              for (let pilo_id of item.pilo_cilos) {
                if (pilo_cilos.length < old_pilo_cilos.length) {
                  pilo_cilos.push({
                    id: old_pilo_cilos[pilo_cilos.length].id,
                    cilo_id: old_pilo_cilos[pilo_cilos.length].cilo_id,
                    pilo_id: pilo_ids[pilo_id]
                  });
                  axios({
                    method: 'post',
                    url: '/api/pc/update',
                    data: {
                      id: pilo_cilos[pilo_cilos.length - 1].id,
                      pilo_id: pilo_cilos[pilo_cilos.length - 1].pilo_id,
                      cilo_id: pilo_cilos[pilo_cilos.length - 1].cilo_id
                    }
                  }).catch(e => {
                    console.log(item.pilo_cilos)
                    console.log(old_pilo_cilos);
                    console.log(pilo_cilos);
                    message.error("Pilo_Cilo failed.");
                    this.setState({
                      loading: false
                    });
                  });
                } else {
                  pilo_cilos.push({
                    cilo_id: cilo_ids[index],
                    pilo_id: pilo_ids[pilo_id]
                  });
                }
              }
            });
            if (pilo_cilos.length > old_pilo_cilos.length) {
              axios({
                method: 'post',
                url: '/api/pc',
                data: {
                  piloCilos: pilo_cilos.slice(old_pilo_cilos.length, pilo_cilos.length)
                }
              });
            }
            values.assessment.forEach((item, index) => {
              for (let cilo_id of item.assessment_cilos) {
                if (ass_cilos.length < old_ass_cilos.length) {
                  ass_cilos.push({
                    id: old_ass_cilos[ass_cilos.length].id,
                    assessment_id: old_ass_cilos[ass_cilos.length].assessment_id,
                    cilo_id: cilo_ids[cilo_id]
                  });
                  axios({
                    method: 'post',
                    url: '/api/ac/update',
                    data: {
                      id: ass_cilos[ass_cilos.length - 1].id,
                      assessment_id: ass_cilos[ass_cilos.length - 1].assessment_id,
                      cilo_id: ass_cilos[ass_cilos.length - 1].cilo_id
                    }
                  }).catch(e => {
                    console.log(item.assessment_cilos);
                    console.log(old_ass_cilos);
                    console.log(ass_cilos);
                    this.setState({
                      loading: false
                    });
                  });
                } else {
                  ass_cilos.push({
                    assessment_id: assessment_ids[index],
                    cilo_id: cilo_ids[cilo_id]
                  });
                }
              }
            });
            if (ass_cilos.length > old_ass_cilos.length) {
              axios({
                method: 'post',
                url: '/api/ac',
                data: {
                  assessmentCilos: ass_cilos.slice(old_ass_cilos.length, ass_cilos.length)
                }
              });
            }
            if (values.pilos.length < data.pilos.length) {
              for (let i = values.pilos.length; i < data.pilos.length; i++) {
                axios({
                  method: 'delete',
                  url: '/api/pilo',
                  params: {
                    id: data.pilos[i].id
                  }
                });
              }
            }
            if (values.cilos.length < data.cilos.length) {
              for (let i = values.cilos.length; i < data.cilos.length; i++) {
                axios({
                  method: 'delete',
                  url: '/api/cilo',
                  params: {
                    id: data.cilos[i].id
                  }
                });
              }
            }
            if (values.assessments.length < data.assessments.length) {
              for (let i = values.assessments.length; i < data.assessments.length; i++) {
                axios({
                  method: 'delete',
                  url: '/api/assessment',
                  params: {
                    id: data.assessments[i].id
                  }
                });
              }
            }
            message.success("Update syllabus information successfully.");
            this.setState({
              loading: false,
              redirect: <Redirect to='/management/syllabus' />
            })
          }
        });
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { data, loading, redirect } = this.state;

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16,
          offset: 1
        },
      },
    };

    /* Contents Information */
    getFieldDecorator('contents', { initialValue: initArr(data.contents.length, 'contents') });
    const contents = getFieldValue('contents');
    const contentItems = contents.map((k, index) => (
      <Form.Item
        label={index === 0 ? 'Course Content' : ''}
        required={false}
        key={k}
      >
        {getFieldDecorator(`content[${k}].title`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input content title.",
            },
          ],
          initialValue: data.contents[k] ? data.contents[k].title : ''
        })(<Input placeholder="Content Title" style={{ width: '70%', marginRight: 5 }} />)}
        {getFieldDecorator(`content[${k}].hours`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              type: 'number',
              transform: value => Number(value),
              required: true,
              whitespace: true,
              message: "Please input hours cost for the content.",
            },
          ],
          initialValue: data.contents[k] ? data.contents[k].hours : ''
        })(<InputNumber placeholder="Hrs" style={{ width: '25%' }} min={1} max={10000} />)}
        {getFieldDecorator(`content[${k}].content`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input content of the part.",
            },
          ],
          initialValue: data.contents[k] ? data.contents[k].content : ''
        })(<TextArea autosize placeholder="Content"
                     style={{ width: "90%", marginRight: 10 }} />)}
        {contents.length > 1 ? (
          <Icon
            type="minus-circle-o"
            onClick={() => this.remove(k, 'contents')}
          />
        ) : null}
      </Form.Item>
    ));

    /* PILO Information */
    getFieldDecorator('pilos', { initialValue: initArr(data.pilos.length, 'pilos') });
    const PILO = getFieldValue('pilos');
    const piloItems = PILO.map((k, index) => (
      <Form.Item
        label={'PILO' + (index + 1)}
        required={false}
        key={k}
      >
        {getFieldDecorator(`pilo[${k}].content`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input content of the PILO.",
            },
          ],
          initialValue: data.pilos[k] ? data.pilos[k].content : ''
        })(<TextArea autosize style={{ width: '90%', marginRight: 10 }}
                     placeholder="PILO Content" />)}
        {PILO.length > 1 ? (<Icon
          type="minus-circle-o"
          onClick={() => this.remove(k, 'pilos')}
        />) : null}
      </Form.Item>
    ));

    /* CILO Information */
    getFieldDecorator('cilos', { initialValue: initArr(data.cilos.length, 'cilos') });
    const CILO = getFieldValue('cilos');
    const ciloItems = CILO.map((k, index) => {
      const pilos = [];
      for (let i = 0; i < PILO.length; i++) {
        pilos.push(<Option key={i}>{"PILO" + (i + 1)}</Option>);
      }

      return (
        <Form.Item
          label={'CILO' + (index + 1)}
          required={false}
          key={k}
        >
          {getFieldDecorator(`cilo[${k}].content`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Please input content of the CILO.",
              },
            ],
            initialValue: data.cilos[k] ? data.cilos[k].content : ''
          })(<TextArea autosize
                       placeholder="CILO Content" />)}
          {getFieldDecorator(`cilo[${k}].pilo_cilos`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                type: 'array',
                required: true,
                message: "Please select corresponding PILOs for the CILO.",
              },
            ],
            initialValue: data.cilos[k] ? getCorrespondPilos(data, k) : []
          })(<Select
            mode='multiple'
            placeholder='Select corresponding PILOs'
            style={{ width: "90%", marginRight: 10 }}
          >
            {pilos}
          </Select>)}
          {CILO.length > 1 ? (
            <Icon
              type="minus-circle-o"
              onClick={() => this.remove(k, 'cilos')}
            />
          ) : null}
        </Form.Item>
      )
    });

    /* Assessment Information */
    getFieldDecorator('assessments', { initialValue: initArr(data.assessments.length, 'assessments') });
    const assessments = getFieldValue('assessments');
    const assessmentItems = assessments.map((k, index) => {
      const cilos = [];
      for (let i = 0; i < CILO.length; i++) {
        cilos.push(<Option key={i}>{"CILO" + (i + 1)}</Option>);
      }

      return (<Form.Item
          label={index === 0 ? 'Assessment Methods' : ''}
          required={false}
          key={k}
        >
          {getFieldDecorator(`assessment[${k}].method`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Please input assessment method.",
              },
            ],
            initialValue: data.assessments[k] ? data.assessments[k].method : ''
          })(<Input placeholder="Assessment Method" />)}
          {getFieldDecorator(`assessment[${k}].weighting`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                type: 'number',
                transform: value => Number(value),
                required: true,
                whitespace: true,
                message: "Please input weighting of assessment method.",
              },
            ],
            initialValue: data.assessments[k] ? data.assessments[k].weighting : ''
          })(<InputNumber placeholder="Weighting(%)" style={{ width: '48%', marginRight: 5 }} max={100} min={1} />)}
          {getFieldDecorator(`assessment[${k}].assessment_cilos`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                type: 'array',
                required: true,
                message: "Please select corresponding CILOs for assessment.",
              },
            ],
            initialValue: data.assessments[k] ? getCorrespondCilos(data, k) : []
          })(<Select
            mode='multiple'
            placeholder='Select corresponding CILOs'
            style={{ width: "48%", marginRight: 10 }}
          >
            {cilos}
          </Select>)}
          {getFieldDecorator(`assessment[${k}].description`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Please input description of assessment method.",
              },
            ],
            initialValue: data.assessments[k] ? data.assessments[k].description : ''
          })(<TextArea autosize placeholder="Description of assessment method"
                       style={{ width: "90%", marginRight: 10 }}/>)}
          {assessments.length > 1 ? (
            <Icon
              type="minus-circle-o"
              onClick={() => this.remove(k, 'assessments')}
            />
          ) : null}
        </Form.Item>
      )});

    /* Textbook Information */
    getFieldDecorator('textBooks', { initialValue: initArr(data.textBooks.length, 'textBooks') });
    const textbooks = getFieldValue('textBooks');
    const textbookItems = textbooks.map((k, index) => (
      <Form.Item
        label={index === 0 ? 'Textbook Information' : ''}
        required={false}
        key={k}
      >
        {getFieldDecorator(`textBook[${k}].title`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input title of the book.",
            },
          ],
          initialValue: data.textBooks[k] ? data.textBooks[k].title : ''
        })(<Input placeholder="Book Title" style={{ width: '60%', marginRight: 5 }} />)}
        {getFieldDecorator(`textBook[${k}].year`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              type: 'number',
              transform: value => Number(value),
              required: true,
              whitespace: true,
              message: "Please input publish year of the book.",
            },
          ],
          initialValue: data.textBooks[k] ? data.textBooks[k].year : ''
        })(<InputNumber placeholder="Publish Year" style={{ width: '35%', marginRight: 5 }} min={1} max={new Date().getFullYear()} />)}
        {getFieldDecorator(`textBook[${k}].author`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input name of authors.",
            },
          ],
          initialValue: data.textBooks[k] ? data.textBooks[k].author : ''
        })(<Input placeholder="Authors' Name" style={{ width: '90%', marginRight: 10 }} />)}
        {textbooks.length > 1 ? (
          <Icon
            type="minus-circle-o"
            onClick={() => this.remove(k, 'textBooks')}
          />
        ) : null}
      </Form.Item>
    ));

    return (
      <div>
        <Bread />
        <Form onSubmit={this.handleSubmit}
              style={{ background: '#fff', margin: 10, padding: 20 }}>
          <Form.Item label="Course Title">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'Please input course title!' }],
              initialValue: data.title
            })(<Input placeholder="Course Title" />,
            )}
          </Form.Item>
          <Form.Item label="Course Code">
            {getFieldDecorator('code', {
              rules: [{ required: true, message: 'Please input course code!' }],
              initialValue: data.code
            })(<Input placeholder="Course Code" />,
            )}
          </Form.Item>
          <Form.Item label="Pre-Requisite">
            {getFieldDecorator('pre_req', { initialValue: data.pre_req })
            (<Input placeholder="Pre-Requisite" />,
            )}
          </Form.Item>
          <Form.Item label="Co-Requisite">
            {getFieldDecorator('co_req', { initialValue: data.co_req })
            (<Input placeholder="Course Code" />,
            )}
          </Form.Item>
          <Form.Item label="Number of Units">
            {getFieldDecorator('unit', {
              rules: [{ required: true, type: 'number', message: 'Please input unit of the course!' }],
              initialValue: data.unit
            })(<InputNumber placeholder="Units" min={1} max={1000} />,
            )}
          </Form.Item>
          <Form.Item label="Contact Hours">
            {getFieldDecorator('hours', {
              rules: [{ required: true, type: 'number', message: 'Please input contact hours!' }],
              initialValue: data.hours
            })(<InputNumber placeholder="Hours" min={1} max={10000} />,
            )}
          </Form.Item>
          <Form.Item label="Offering Unit">
            {getFieldDecorator('offer_unit', {
              rules: [{ required: true, message: 'Please input the department which provide the unit!' }],
              initialValue: data.offer_unit
            })(<Input placeholder="Offering Unit" />,
            )}
          </Form.Item>
          <Form.Item label="Syllabus Prepared & Reviewed by">
            {getFieldDecorator('prepare', {
              rules: [{ required: true, message: 'Please input people who prepared the document!' }],
              initialValue: data.prepare
            })(<Input placeholder="Prepared by" />,
            )}
            {getFieldDecorator('review', {
              rules: [{ required: true, message: 'Please input people who reviewed the document!' }],
              initialValue: data.review
            })(<Input placeholder="Reviewed by" />,
            )}
          </Form.Item>
          <Form.Item label="Aims & Objectives">
            {getFieldDecorator('aim', {
              rules: [{ required: true, message: 'Please input aims and objectives of the course!' }],
              initialValue: data.aim
            })(<TextArea autosize placeholder="Aims & Objectives" />,
            )}
          </Form.Item>
          {contentItems}
          <Form.Item>
            <Button type="dashed" onClick={() => this.add('contents')}
                    style={{ marginLeft: '10%', width: '80%' }}>
              <Icon type="plus" /> Add Course Content
            </Button>
          </Form.Item>
          {piloItems}
          <Form.Item>
            <Button type="dashed" onClick={() => this.add('pilos')}
                    style={{ marginLeft: '10%', width: '80%' }}>
              <Icon type="plus" /> Add PILO Content
            </Button>
          </Form.Item>
          {ciloItems}
          <Form.Item>
            <Button type="dashed" onClick={() => this.add('cilos')}
                    style={{ marginLeft: '10%', width: '80%' }}>
              <Icon type="plus" /> Add CILO Content
            </Button>
          </Form.Item>
          <Text strong>TLAs</Text>
          {CILO.map((k, index) => {
              return (<Form.Item label={"CILO" + (index + 1)}>
                {getFieldDecorator(`tla[${k}].content`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input TLA of the CILO.",
                    },
                  ],
                  initialValue: data.cilos[k] ? data.cilos[k].tla.content : ''
                })(<TextArea autosize placeholder="TLA Content" />)}
              </Form.Item>)
            }
          )}
          {assessmentItems}
          <Form.Item>
            <Button type="dashed" onClick={() => this.add('assessments')}
                    style={{ marginLeft: '10%', width: '80%' }}>
              <Icon type="plus" /> Add Assessment Methods
            </Button>
          </Form.Item>
          {textbookItems}
          <Form.Item>
            <Button type="dashed" onClick={() => this.add('textBooks')}
                    style={{ marginLeft: '10%', width: '80%' }}>
              <Icon type="plus" /> Add Textbook Information
            </Button>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" style={{ width: 100, marginRight: 10 }} loading={loading}>
              Update
            </Button>
            <Button htmlType="button" style={{ width: 100 }} onClick={() => window.history.go(-1)}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
        {redirect}
      </div>
    );
  }
}

export const WrappedEditSyllabusForm = Form.create({ name: 'edit_syllabus' })(EditSyllabusForm);
