import { Form, Icon, Button, Upload, Typography, Breadcrumb, message, Select } from 'antd';
import React from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { UserInfo } from "../../Functions";

const { Title } = Typography;
const { Option } = Select;


class NewTeacherForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    redirect: null,
    schools: [],
    fileList: [],
    filename: '',
    user: null,
    loading: false
  };

  constructor(...props) {
    super(...props);
    axios({
      method: 'get',
      url: '/api/schools'
    }).then(rsp => {
      this.setState({
        schools: rsp.data.schools
      });
    });
    axios({
      method: 'post',
      url: '/api/token',
      data: {
        token: new UserInfo().getUserInfo().token
      }
    }).then(rsp => {
      this.setState({
        user: rsp.data.user
      });
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        let token = new UserInfo().getUserInfo().token;
        axios({
          method: 'post',
          url: '/api/audit/teacher',
          data: {
            token: token,
            description: this.state.filename,
            school_name: values.school
          }
        }).then(rsp => {
          if (rsp.data.status) {
            message.success("Successfully send application.");
            this.setState({
              redirect: <Redirect to='/profile' />,
              loading: false
            });
          }
          else {
            message.error("Do not submit application twice!");
            this.setState({ loading: false });
          }
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { redirect, schools, fileList, user, loading } = this.state;
    const schoolOptions = schools.map(item => (
      <Option value={item.name}>{item.name}</Option>
    ));

    return (
      <div style={{ background: "#fff", padding: 20 }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/profile">Profile</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Teacher</Breadcrumb.Item>
        </Breadcrumb>
        {redirect}
        <Title level={3}>Teacher Application Form</Title>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="School">
            {getFieldDecorator('school', {
              rules: [
                { required: true, message: "Please select user's role!" },
              ],
            })(<Select showSearch>
              {schoolOptions}
            </Select>)}
          </Form.Item>
          <Form.Item label='Upload'>
            <Upload
              name="logo"
              customRequest={options => {
                const { onSuccess, file, onProgress } = options;
                let formData = new FormData();
                formData.append("file", file);
                formData.append("category", "teacher");
                formData.append("user", user.name);
                axios({
                  method: 'post',
                  url: '/api/school/upload',
                  data: formData,
                  headers: { 'Content-Type': 'multipart/form-data' },
                  onUploadProgress: e => {
                    onProgress({ percent: (e.loaded / e.total) * 100 });
                  }
                }).then(rsp => {
                  let filename = rsp.data.name;
                  this.setState({
                    filename
                  });
                  onSuccess(rsp.data);
                });
              }}
              fileList={fileList}
              onChange={info => {
                let fileList = [...info.fileList];
                fileList = fileList.slice(-1);

                fileList = fileList.map(file => {
                  if (file.response) {
                    // Component will show file.url as link
                    file.url = file.response.url;
                  }
                  return file;
                });

                this.setState({ fileList });
              }}
            >
              <Button>
                <Icon type="upload" /> Click to upload
              </Button>
            </Upload>
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>Apply</Button>
          <Button onClick={() => window.history.back()} style={{ marginLeft: 20 }}>Cancel</Button>
        </Form>
      </div>
    );
  }
}

export const WrappedNewTeacherForm = Form.create({ name: 'new_teacher' })(NewTeacherForm);
