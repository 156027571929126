import React from 'react';
import { Typography, Card, Tabs, Tooltip, Icon, Divider, Table, Breadcrumb, Tag, message, Skeleton, Avatar } from "antd/lib/index";
import { Link } from "react-router-dom";
import axios from 'axios';
import { UserInfo } from "../Functions";
import Clipboard from 'react-clipboard.js';
import Swal from 'sweetalert2';

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const roles = [
  {
    name: 'Admin',
    color: 'red'
  },
  {
    name: 'School',
    color: 'gold'
  },
  {
    name: 'Teacher',
    color: 'blue'
  },
  {
    name: 'User',
    color: 'green'
  }
];
const nullUser = {
  id: null,
  username: null,
  email: null,
  role_id: 4,
  school: null
};

export class Profile extends React.Component {
  state = {
    user: nullUser,
    courses: [],
    schools: [],
    loading: true,
    school: null,
    avatar: ''
  };

  constructor(...props) {
    super(...props);
    const userInfo = new UserInfo();
    let session = userInfo.getUserInfo();
    if (!session) {
      this.setState({
        user: nullUser
      });
      return;
    }
    else if (session === -1) {
      message.error("User session expired");
      this.setState({
        user: nullUser
      });
      return;
    }
    axios({
      method: 'post',
      url: '/api/token',
      data: {
        token: session.token
      }
    }).then(rsp => {
      if (rsp.data.user) {
        let user = {
          id: rsp.data.user.id,
          email: rsp.data.user.email,
          username: rsp.data.user.name,
          role_id: rsp.data.user.role.id,
          school: rsp.data.user.school
        };
        axios({
          method: 'post',
          url: '/api/user/avatar',
          data: {
            token: userInfo.getUserInfo().token
          }
        }).then(response => {
          if (response.data) {
            this.setState({
              avatar: '/static/img/avatar/' + response.data
            })
          }
        });
        if (user.role_id === 2) {
          axios({
            method: 'get',
            url: '/api/school',
            params: {
              id: user.school.id
            }
          }).then(rsp => {
            this.setState({
              school: rsp.data.school
            });
          });
        }
        this.setState({
          user
        }, () => {
          axios({
            method: 'get',
            url: '/api/favorite/syllabus',
            params: {
              user_id: user.id
            }
          }).then(rsp => {
            this.setState({
              courses: rsp.data.syllabuses,
              loading: false
            });
          });
          axios({
            method: 'get',
            url: '/api/favorite/school',
            params: {
              user_id: user.id
            }
          }).then(rsp => {
            this.setState({
              schools: rsp.data.schools,
              loading: false
            });
          });
        });
      }
      else {
        message.error("User Authentication failed");
        window.localStorage.removeItem('userEncrypted');
        this.setState({
          user: nullUser
        });
      }
    });
  }

  removeFavorite = (id, category) => {
    if (category === 'course') {
      let courses = this.state.courses;
      courses.forEach((item, index) => {
        if (item.id === id) {
          Swal.fire({
            title: 'Are you sure?',
            text: 'You are removing ' + item.title + '(' + item.school.name + ')',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Remove',
            showLoaderOnConfirm: true,
            preConfirm: () => {
              const userInfo = new UserInfo();
              return axios({
                method: 'delete',
                url: '/api/favorite',
                data: {
                  token: userInfo.getUserInfo().token,
                  syllabus_id: item.id,
                  school_id: 0
                }
              });
            }
          }).then(result => {
            if (result.value) {
              courses.splice(index, 1);
              this.setState({
                courses
              });
              message.success("Removed " + item.title
                + "(" + item.school.name + ') from favorite!');
            }
          });
        }
      });
    } else if (category === 'school') {
      let schools = this.state.schools;
      schools.forEach((item, index) => {
        if (item.id === id) {
          Swal.fire({
            title: 'Are you sure?',
            text: 'You are removing ' + item.name + '(' + item.location + ')',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Remove',
            showLoaderOnConfirm: true,
            preConfirm: () => {
              const userInfo = new UserInfo();
              return axios({
                method: 'delete',
                url: '/api/favorite',
                data: {
                  token: userInfo.getUserInfo().token,
                  syllabus_id: 0,
                  school_id: item.id
                }
              });
            }
          }).then(result => {
            if (result.value) {
              schools.splice(index, 1);
              this.setState({
                schools
              });
              message.success("Removed " + item.name
                + "(" + item.location + ') from favorite!');
            }
          });
        }
      });
    }
  };



  render() {
    const { user, courses, schools, loading, school, avatar } = this.state;
    const columns = {
      course: [
        {
          title: 'Name',
          dataIndex: 'title',
          render: (text, record) => (<Link to={{
            pathname: '/course/detail/' + record.id,
            state: {
              referrer: this.props.location.pathname
            }
          }}>{text}</Link>)
        },
        {
          title: 'School',
          dataIndex: 'school.name',
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <span>
          <Tooltip title="Remove from favorite">
            <a onClick={() => this.removeFavorite(record.id, 'course')}><Icon type="star" theme="filled" /></a>
          </Tooltip>
          <Divider type="vertical" />
          <Clipboard
            data-clipboard-text={"https://syllabus.drjchn.com/course/detail/" + record.id}
            component="a"
            onSuccess={() => message.success("Course detail link has copied!")}
          >
            <Tooltip title="Copy Link">
              <Icon type="copy" />
            </Tooltip>
          </Clipboard>
        </span>
          )
        }
      ],
      school: [
        {
          title: 'Name',
          dataIndex: 'name',
          render: (text, record) => (<Link to={{
            pathname: '/school/detail/' + record.id,
            state: {
              referrer: this.props.location.pathname
            }
          }}>{text}</Link>)
        },
        {
          title: 'Location',
          dataIndex: 'location',
          key: 'location'
        },
        {
          title: 'Action',
          key: 'action',
          render: (text, record) => (
            <span>
          <Tooltip title="Remove from favorite">
            <a onClick={() => this.removeFavorite(record.id, 'school')}><Icon type="star" theme="filled" /></a>
          </Tooltip>
          <Divider type="vertical" />
          <Clipboard
            data-clipboard-text={"https://syllabus.drjchn.com/school/detail/" + record.id}
            component="a"
            onSuccess={() => message.success("School detail link has copied!")}
          >
            <Tooltip title="Copy Link">
              <Icon type="copy" />
            </Tooltip>
          </Clipboard>
        </span>
          )
        }
      ]
    };

    return (
        <div style={{ background: '#fff', padding: 20 }}>
          <Skeleton active loading={loading}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to='/'>Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Profile</Breadcrumb.Item>
          </Breadcrumb>
          <Card title="User Details" extra={<Link to='/profile/edit'>Edit Profile</Link>} style={{ marginTop: 20 }}>
            <Avatar icon="user" src={avatar} size={100} style={{ marginBottom: 20 }} />
            <Title level={4}>Role</Title>
            <Tag
              color={roles[user.role_id - 1]['color']}
              style={{ marginBottom: 10 }}
            >{roles[user.role_id - 1]['name']}</Tag>
            <Title level={4}>Email</Title>
            <p>{user.email}</p>
            <Title level={4}>Username</Title>
            <p>{user.username}</p>
            <Title level={4}>School</Title>
            <p>{user.school ? user.school.name : 'None'}</p>
            <p><Link to='/profile/change_password'><strong>Change Password</strong></Link></p>
            {user.role_id === 2 && (<p><Link to={{
              pathname: '/profile/update_school',
              state: {
                data: school
              }
            }}><strong>Update School Profile</strong></Link></p>)}
            {user.role_id === 4 && (<Paragraph>
              Apply for <Link to='/profile/school'>School Administrator</Link> /
              <Link to='/profile/teacher'> Teacher</Link>
            </Paragraph>)}
          </Card>
          <br />
          <Title level={3}>My Favorites</Title>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Course" key="1">
              <Table columns={columns['course']} dataSource={courses}
                     rowKey='id' style={{ background: '#fff', marginTop: 20 }} />
            </TabPane>
            <TabPane tab="School" key="2">
              <Table columns={columns['school']} dataSource={schools}
                     rowKey='id' style={{ background: '#fff', marginTop: 20 }} />
            </TabPane>
          </Tabs>
          </Skeleton>
        </div>
    )
  }
}
