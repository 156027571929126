import React from 'react';
import { Breadcrumb, Typography, Skeleton, Badge } from "antd";
import { Link } from "react-router-dom";
import axios from 'axios';
import { UserInfo } from "../Functions";

const { Title } = Typography;

export class Management extends React.Component {
  state = {
    user: null,
    loading: true,
    dot: false,
    dots: false
  };

  constructor(...props) {
    super(...props);
    const userInfo = new UserInfo();
    let session = userInfo.getUserInfo();
    axios({
      method: 'post',
      url: '/api/token',
      data: {
        token: session.token
      }
    }).then(rsp => {
      if (rsp.data.user.role.id === 1) {
        axios({
          method: 'get',
          url: '/api/audits',
        }).then(response => {
          this.setState({
            dots: response.data.audits.length > 0,
            loading: false
          });
        });
      }
      else if (rsp.data.user.role.id === 2) {
        axios({
          method: 'post',
          url: '/api/audit/teachers',
          data: {
            token: new UserInfo().getUserInfo().token
          }
        }).then(response => {
          this.setState({
            dot: response.data.audits.length > 0,
            loading: false
          });
        });
      } else {
        this.setState({
          loading: false
        })
      }
      let user = {
        username: rsp.data.user.name,
        role_id: rsp.data.user.role.id
      };
      this.setState({
        user
      });
    });
  }

  render() {
    const { user, loading, dot, dots } = this.state;

    return (
        <div style={{ background: '#fff', padding: 20 }}>
          <Skeleton loading={loading} active>
          <Breadcrumb>
            <Breadcrumb.Item><Link to='/'>Home</Link></Breadcrumb.Item>
            <Breadcrumb.Item>Management</Breadcrumb.Item>
          </Breadcrumb>
          <Title level={2}>Management</Title>
          <Title level={4}><Link to="/management/syllabus">Syllabus Management</Link></Title>
          {user && user.role_id < 3 && (
            <Badge dot={dot}><Title level={4}><Link to="/management/user">User Management</Link></Title></Badge>
          )}
            <br />
          {user && user.role_id < 2 && (
            <Badge dot={dots}><Title level={4}><Link to="/management/school">School Management</Link></Title></Badge>
          )}
          </Skeleton>
        </div>
    )
  }
}
