import React from "react";
import { Input, Table, Icon, Divider, Tooltip, Button, Row, Breadcrumb, message, Skeleton } from "antd";
import { Link } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';
import { UserInfo } from "../../Functions";

const search_word = (keyword, origin) => {
  return origin.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
};

export class SyllabusManagement extends React.Component {
  state = {
    disableDelete: true,
    selectedRowKeys: [],
    data: [],
    results: [],
    loading: true,
    keyword: {
      course: '',
      school: ''
    },
    role_id: 4
  };

  constructor(...props) {
    super(...props);
    const userInfo = new UserInfo();
    let token = userInfo.getUserInfo().token;
    axios({
      method: 'post',
      url: '/api/token',
      data: {
        token: token
      }
    }).then(rsp => {
      this.setState({
        role_id: rsp.data.user.role.id
      });
      axios({
        method: 'post',
        url: '/api/syllabuses',
        data: {
          token: token
        }
      }).then(rsp => {
        this.setState({
          data: rsp.data.syllabuses,
          results: rsp.data.syllabuses,
          loading: false
        })
      });
    });
  };

  deleteItem = id => {
    let data = this.state.data;
    data.forEach((item, index) => {
      if (item.id === id) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You are deleting ' + item.title + '(' + item.school.name + ')',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Delete',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return axios({
              method: 'delete',
              url: '/api/syllabus',
              params: {
                id: item.id
              }
            });
          }
        }).then(result => {
          if (result.value) {
            data.splice(index, 1);
            this.setState({
              data
            });
            message.success("Successfully deleted " + item.title + '(' + item.school.name + ')')
          }
        });
      }
    });
  };

  deleteItems = () => {
    const { selectedRowKeys } = this.state;
    for (let i of selectedRowKeys) {
      axios({
        method: 'delete',
        url: '/api/syllabus',
        params: {
          id: i
        }
      });
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys,
      disableDelete: selectedRowKeys.length === 0
    });
  };

  handleChange = (e, category) => {
    let keyword = this.state.keyword;
    keyword[category] = e.target.value;
    this.setState({
      keyword
    });
  };

  handleClear = e => {
    const { data } = this.state;
    let keyword = this.state.keyword;
    keyword['course'] = '';
    keyword['school'] = '';
    this.setState({
      keyword,
      results: data
    });
  };

  handleSearch = () => {
    const { data, keyword } = this.state;
    if (keyword['course'].length === 0 && keyword['school'].length === 0)
      return;
    let results = [];
    data.forEach(item => {
      if (keyword['school'].length === 0) {
        if (search_word(keyword['course'], item.title))
          results.push(item);
      } else if (keyword['course'].length === 0) {
        if (search_word(keyword['school'], item.school.name))
          results.push(item);
      } else {
        if (search_word(keyword['course'], item.title) && search_word(keyword['school'], item.school.name))
          results.push(item);
      }
    });
    this.setState({
      results
    });
  };

  render() {
    const { data, disableDelete, selectedRowKeys, loading, results, keyword, role_id } = this.state;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'title',
        key: 'title',
        render: (text, record) => (<Link to={{
          pathname: '/course/detail/' + record.id,
          state: {
            referrer: this.props.location.pathname
          }
        }}>{text}</Link>)
      },
      {
        title: 'School',
        dataIndex: 'school.name',
        key: 'school'
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            {(role_id === 2 || role_id === 3) && (
              [<Tooltip title="Edit">
                <Link to={{
                  pathname: '/management/syllabus/edit',
                  state: {
                    data: record
                  }
                }}><Icon type="edit" /></Link>
              </Tooltip>,
                <Divider type="vertical" />]
            )}
            <Tooltip title="Delete">
          <a onClick={() => this.deleteItem(record.id)}><Icon type="delete" /></a>
        </Tooltip>
      </span>
        )
      }
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const deleteWords = () => {
      let char = [];
      data.forEach(item => {
        if (selectedRowKeys.indexOf(item.id) !== -1)
          char.push(item.title + '(' + item.school.name + ')');
      });
      return char;
    };

    return (
      <Skeleton active loading={loading}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/management">Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Syllabus Management</Breadcrumb.Item>
        </Breadcrumb>
        <Input
          placeholder='Course'
          allowClear
          value={keyword['course']}
          onChange={e => this.handleChange(e, 'course')}
          style={{ marginTop: 10 }}
          onKeyDown={e => {
            if (e.keyCode === 13) this.handleSearch();
          }}
        />
        <Input
          placeholder='School'
          allowClear
          value={keyword['school']}
          onChange={e => this.handleChange(e, 'school')}
          style={{ marginTop: 10 }}
          onKeyDown={e => {
            if (e.keyCode === 13) this.handleSearch();
          }}
        />
        <Row style={{ marginTop: 10, marginRight: 10 }}>
          <Button
            type='primary'
            style={{ float: 'right' }}
            onClick={this.handleSearch}
          >Search</Button>
          <Button
            style={{ float: 'right', marginRight: 20 }}
            onClick={this.handleClear}
          >Clear</Button>
        </Row>
        <Row>
          {(role_id === 2 || role_id === 3) && (
            <Link to={'/management/syllabus/add'}><Button type="primary">New Syllabus</Button></Link>
          )}
          <Button
            type="danger"
            disabled={disableDelete}
            style={{ marginTop: 20, marginLeft: 20 }}
            onClick={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: 'You are deleting ' + deleteWords(),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Delete',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                  return this.deleteItems();
                }
              }).then(result => {
                if (result.value) {
                  let changedData = data.filter(item => selectedRowKeys.indexOf(item.id) === -1);
                  this.setState({
                    visible: false,
                    disableDelete: true,
                    selectedRowKeys: [],
                    data: changedData,
                    results: changedData
                  }, () => message.success("Successfully deleted", 2));
                }
              });
            }}
          >
            Delete
          </Button>
        </Row>
        <Table columns={columns} dataSource={results} rowSelection={rowSelection}
               rowKey='id' style={{ background: '#fff', marginTop: 20 }} />
      </Skeleton>
    )
  }
}
