import { Form, Input, Button, Typography, message, Alert } from 'antd';
import React from 'react';
import axios from 'axios';
import { Link, Redirect } from "react-router-dom";

const { Title, Text } = Typography;


class ResetPasswordForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    redirect: null,
    loading: false,
    load: false
  };

  constructor(...props) {
    super(...props);
    let token = this.props.match.params.token;
    axios({
      method: 'post',
      url: '/api/password/check',
      data: {
        token: token
      }
    }).then(rsp => {
      this.setState({
        load: rsp.data.status
      });
    });
  }

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        axios({
          method: 'post',
          url: '/api/password/reset',
          data: {
            token: this.props.match.params.token,
            old_pass: 'old',
            new_pass: values.password
          }
        }).then(rsp => {
          if (rsp.data.status) {
            message.success("Successfully reset password!");
            this.setState({
              loading: false,
              redirect: <Redirect to='/login' />
            });
          }
          else {
            message.error("Reset password failed");
            this.setState({
              loading: false
            })
          }
        });
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { redirect, loading, load } = this.state;

    return load ? (
      <div style={{ background: "#fff", padding: 20 }}>
        <Title level={3}>Reset Password</Title>
        {redirect}
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Password" hasFeedback>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please input your new password!',
                },
                {
                  validator: this.validateToNextPassword,
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label="Confirm Password" hasFeedback>
            {getFieldDecorator('confirm', {
              rules: [
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                {
                  validator: this.compareToFirstPassword,
                },
              ],
            })(<Input.Password onBlur={this.handleConfirmBlur} />)}
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Reset
          </Button>
          <Button onClick={() => window.history.back()} style={{ marginLeft: 20 }}>Cancel</Button>
        </Form>
      </div>
    ) : (
        <Alert message="Wrong token or link expired" type="warning" description={<Text>Your reset token is invalid or expired. Click <Link to='/'>here</Link> to go back to homepage.</Text>}  />
      );
  }
}

export const WrappedResetPasswordForm = Form.create({ name: 'reset_password' })(ResetPasswordForm);
