import { Form, Input, Button, Typography, Breadcrumb, message } from 'antd';
import React from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';

const { Title } = Typography;
const { TextArea } = Input;


class EditSchoolForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    loading: false,
    redirect: null
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        axios({
          method: 'post',
          url: '/api/school/update',
          data: {
            name: values.name,
            location: values.location,
            introduction: values.introduction,
            id: this.props.location.state.data.id
          }
        }).then(rsp => {
          if (rsp.data.status) {
            message.success("School profile updated successfully!");
            this.setState({
              loading: false,
              redirect: <Redirect to='/profile' />
            });
          }
          else {
            message.error("Error when updating school profile.");
            this.setState({
              loading: false
            });
          }
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const initialData = this.props.location.state.data;
    const { loading, redirect } = this.state;

    return (
      <div style={{ background: "#fff", padding: 20 }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/management">Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/management/school">School Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Edit</Breadcrumb.Item>
        </Breadcrumb>
        <Title level={3}>Edit School</Title>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please input school name',
                },
              ],
              initialValue: initialData.name
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Location" hasFeedback>
            {getFieldDecorator('location', {
              rules: [
                {
                  required: true,
                  message: 'Please input the country of the school',
                }
              ],
              initialValue: initialData.location
            })(<Input />)}
          </Form.Item>
          <Form.Item label='Introduction'>
            {getFieldDecorator('introduction', {
              rules: [{ required: true, message: 'Please input introduction of school', whitespace: true }],
              initialValue: initialData.introduction
            })(<TextArea autosize placeholder='Introduction' />)}
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>Update</Button>
          <Button onClick={() => window.history.back()} style={{ marginLeft: 20 }}>Cancel</Button>
        </Form>
        {redirect}
      </div>
    );
  }
}

export const WrappedEditSchoolForm = Form.create({ name: 'edit_school' })(EditSchoolForm);
