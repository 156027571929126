import React from "react";
import { Breadcrumb, Input, InputNumber, Form, Icon, Button, Select, Typography, message } from "antd";
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import { UserInfo } from "../../Functions";

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const Bread = () => (
  <Breadcrumb>
    <Breadcrumb.Item>
      <Link to="/">Home</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <Link to="/management">Management</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <Link to="/management/syllabus">Syllabus Management</Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item>New</Breadcrumb.Item>
  </Breadcrumb>
);

let id = {
  contents: 1,
  pilos: 1,
  cilos: 1,
  assessments: 1,
  textBooks: 0
};

class NewSyllabusForm extends React.Component {
  state = {
    school_id: 0,
    id: null,
    redirect: null,
    loading: false,
    editor_id: 0
  };

  constructor(...props) {
    super(...props);
    const userInfo = new UserInfo();
    axios({
      method: 'post',
      url: '/api/token',
      data: {
        token: userInfo.getUserInfo().token
      }
    }).then(rsp => {
      this.setState({
        school_id: rsp.data.user.school.id,
        editor_id: rsp.data.user.id
      });
    });
  }

  remove = (k, component) => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue(component);
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }
    id[component]--;
    // can use data-binding to set
    form.setFieldsValue({
      [component]: keys.filter(key => key !== k),
    });
  };

  add = component => {
    const { form } = this.props;
    // can use data-binding to get
    const keys = form.getFieldValue(component);
    const nextKeys = keys.concat(id[component]++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      [component]: nextKeys,
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { school_id } = this.state;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        axios({
          method: 'post',
          url: '/api/syllabus',
          data: {
            school_id: school_id,
            code: values.code,
            title: values.title,
            prepare: values.prepare,
            review: values.review,
            unit: values.unit,
            hours: values.hours,
            pre_req: values.pre_req,
            co_req: values.co_req,
            offer_unit: values.offer_unit,
            aim: values.aim,
            editor_id: this.state.editor_id
          }
        }).then(rsp => {
          this.setState({
            id: rsp.data.syllabus.id
          }, () => {
            if (values.textBooks.length > 0) {
              values.textBook.forEach(item => {
                item.syllabus_id = this.state.id;
              });
              axios({
                method: 'post',
                url: '/api/textBook',
                data: {
                  textBooks: values.textBook
                }
              });
            }
            if (values.contents.length > 0) {
              values.content.forEach((item, index) => {
                item.content_id = index + 1;
                item.syllabus_id = this.state.id;
              });
              axios({
                method: 'post',
                url: '/api/content',
                data: {
                  contents: values.content
                }
              });
            }
            if (values.pilos.length > 0) {
              values.pilo.forEach((item, index) => {
                item.pilo_id = index + 1;
                item.syllabus_id = this.state.id;
              });
              axios({
                method: 'post',
                url: '/api/pilo',
                data: {
                  pilos: values.pilo
                }
              }).then(rsp => {
                let pilo_ids = rsp.data.ID;
                values.cilo.forEach((item, index) => {
                  item.cilo_id = index + 1;
                  item.syllabus_id = this.state.id;
                });
                axios({
                  method: 'post',
                  url: '/api/cilo',
                  data: {
                    cilos: values.cilo
                  }
                }).then(rsp => {
                  let cilo_ids = rsp.data.ID;
                  let pilo_cilos = [];
                  values.cilo.forEach((item, index) => {
                    for (let pilo_index of item.pilo_ids) {
                      pilo_cilos.push({
                        pilo_id: pilo_ids[pilo_index],
                        cilo_id: cilo_ids[index]
                      });
                    }
                  });
                  axios({
                    method: 'post',
                    url: '/api/pc',
                    data: {
                      piloCilos: pilo_cilos
                    }
                  });
                  values.tla.forEach((item, index) => {
                    item.cilo_id = cilo_ids[index];
                  });
                  if (values.assessments.length > 0) {
                    values.assessment.forEach(item => {
                      item.syllabus_id = this.state.id;
                    });
                    axios({
                      method: 'post',
                      url: '/api/assessment',
                      data: {
                        assessments: values.assessment
                      }
                    }).then(rsp => {
                      let assessment_ids = rsp.data.ID;
                      let assessment_cilos = [];
                      values.assessment.forEach((item, index) => {
                        for (let cilo_index of item.cilo_ids) {
                          assessment_cilos.push({
                            assessment_id: assessment_ids[index],
                            cilo_id: cilo_ids[cilo_index]
                          });
                        }
                      });
                      axios({
                        method: 'post',
                        url: '/api/ac',
                        data: {
                          assessmentCilos: assessment_cilos
                        }
                      });
                    });
                  }
                  axios({
                    method: 'post',
                    url: '/api/tla',
                    data: {
                      tlas: values.tla
                    }
                  }).then(rsp => {
                    this.setState({
                      loading: false,
                      redirect: <Redirect to='/management/syllabus' />
                    });
                    message.success("New content added successfully!");
                  });
                });
              }).catch(e => {
                console.log(values.pilo);
                console.log(values.pilos);
                message.error("Pilo insert failed");
                this.setState({
                  loading: false
                });
              });
            } else {
              this.setState({
                loading: false,
                redirect: <Redirect to='/management/syllabus' />
              });
              message.success("New content added successfully!");
            }
          });
        }).catch(err => {
          console.log(err);
          message.error("Do not submit same syllabus twice.");
          this.setState({
            loading: false,
            redirect: <Redirect to='/management/syllabus' />
          });
        });
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { loading, redirect } = this.state;

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24
        },
        sm: {
          span: 16,
          offset: 1
        },
      },
    };

    /* Contents Information */
    getFieldDecorator('contents', { initialValue: [0] });
    const contents = getFieldValue('contents');
    const contentItems = contents.map((k, index) => (
      <Form.Item
        label={index === 0 ? 'Course Content' : ''}
        required={false}
        key={k}
      >
        {getFieldDecorator(`content[${k}].title`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input content title.",
            },
          ],
        })(<Input placeholder="Content Title" style={{ width: '70%', marginRight: 5 }} />)}
        {getFieldDecorator(`content[${k}].hours`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              type: 'number',
              transform: value => Number(value),
              required: true,
              whitespace: true,
              message: "Please input hours cost for the content.",
            },
          ],
        })(<InputNumber placeholder="Hrs" style={{ width: '25%' }} min={1} max={10000} />)}
        {getFieldDecorator(`content[${k}].content`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input content of the part.",
            },
          ],
        })(<TextArea autosize placeholder="Content"
                     style={{ width: "90%", marginRight: 10 }} />)}
        {contents.length > 1 ? (
          <Icon
            type="minus-circle-o"
            onClick={() => this.remove(k, 'contents')}
          />
        ) : null}
      </Form.Item>
    ));

    /* PILO Information */
    getFieldDecorator('pilos', { initialValue: [0] });
    const PILO = getFieldValue('pilos');
    const piloItems = PILO.map((k, index) => (
      <Form.Item
        label={'PILO' + (index + 1)}
        required={false}
        key={k}
      >
        {getFieldDecorator(`pilo[${k}].content`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input content of the PILO.",
            },
          ],
        })(<TextArea autosize style={{ width: '90%', marginRight: 10 }}
                     placeholder="PILO Content" />)}
        {PILO.length > 1 ? (<Icon
            type="minus-circle-o"
            onClick={() => this.remove(k, 'pilos')}
          />) : null}
      </Form.Item>
    ));

    /* CILO Information */
    getFieldDecorator('cilos', { initialValue: [0] });
    const CILO = getFieldValue('cilos');
    const ciloItems = CILO.map((k, index) => {
      const pilos = [];
      for (let i = 0; i < PILO.length; i++) {
        pilos.push(<Option key={i}>{"PILO" + (i + 1)}</Option>);
      }

      return (
        <Form.Item
          label={'CILO' + (index + 1)}
          required={false}
          key={k}
        >
          {getFieldDecorator(`cilo[${k}].content`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                whitespace: true,
                message: "Please input content of the CILO.",
              },
            ],
          })(<TextArea autosize
                       placeholder="CILO Content" />)}
          {getFieldDecorator(`cilo[${k}].pilo_ids`, {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                type: 'array',
                required: true,
                message: "Please select corresponding PILOs for the CILO.",
              },
            ],
          })(<Select
            mode='multiple'
            placeholder='Select corresponding PILOs'
            style={{ width: "90%", marginRight: 10 }}
          >
            {pilos}
          </Select>)}
          {CILO.length > 1 ? (
            <Icon
              type="minus-circle-o"
              onClick={() => this.remove(k, 'cilos')}
            />
          ) : null}
        </Form.Item>
      )
    });

    /* Assessment Information */
    getFieldDecorator('assessments', { initialValue: [0] });
    const assessments = getFieldValue('assessments');
    const assessmentItems = assessments.map((k, index) => {
      const cilos = [];
      for (let i = 0; i < CILO.length; i++) {
        cilos.push(<Option key={i}>{"CILO" + (i + 1)}</Option>);
      }

      return (<Form.Item
        label={index === 0 ? 'Assessment Methods' : ''}
        required={false}
        key={k}
      >
        {getFieldDecorator(`assessment[${k}].method`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input assessment method.",
            },
          ],
        })(<Input placeholder="Assessment Method" />)}
        {getFieldDecorator(`assessment[${k}].weighting`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              type: 'number',
              transform: value => Number(value),
              required: true,
              whitespace: true,
              message: "Please input weighting of assessment method.",
            },
          ],
        })(<InputNumber placeholder="Weighting(%)" style={{ width: '48%', marginRight: 5 }} max={100} min={1} />)}
        {getFieldDecorator(`assessment[${k}].cilo_ids`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              type: 'array',
              required: true,
              message: "Please select corresponding CILOs for assessment.",
            },
          ],
        })(<Select
          mode='multiple'
          placeholder='Select corresponding CILOs'
          style={{ width: "48%", marginRight: 10 }}
        >
          {cilos}
        </Select>)}
        {getFieldDecorator(`assessment[${k}].description`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input description of assessment method.",
            },
          ],
        })(<TextArea autosize placeholder="Description of assessment method"
        style={{ width: "90%", marginRight: 10 }}/>)}
        {assessments.length > 1 ? (
          <Icon
            type="minus-circle-o"
            onClick={() => this.remove(k, 'assessments')}
          />
        ) : null}
      </Form.Item>
    )});

    /* Textbook Information */
    getFieldDecorator('textBooks', { initialValue: [] });
    const textbooks = getFieldValue('textBooks');
    const textbookItems = textbooks.map((k, index) => (
      <Form.Item
        label={index === 0 ? 'Textbook Information' : ''}
        required={false}
        key={k}
      >
        {getFieldDecorator(`textBook[${k}].title`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input title of the book.",
            },
          ],
        })(<Input placeholder="Book Title" style={{ width: '60%', marginRight: 5 }} />)}
        {getFieldDecorator(`textBook[${k}].year`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              type: 'number',
              transform: value => Number(value),
              required: true,
              whitespace: true,
              message: "Please input publish year of the book.",
            },
          ],
        })(<InputNumber placeholder="Publish Year" style={{ width: '35%', marginRight: 5 }} min={1} max={new Date().getFullYear()} />)}
        {getFieldDecorator(`textBook[${k}].author`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please input name of authors.",
            },
          ],
        })(<Input placeholder="Authors' Name" style={{ width: '90%', marginRight: 10 }} />)}
        {textbooks.length > 1 ? (
          <Icon
            type="minus-circle-o"
            onClick={() => this.remove(k, 'textBooks')}
          />
        ) : null}
      </Form.Item>
    ));

    return (
      <div>
        <Bread />
        <Form onSubmit={this.handleSubmit}
              style={{ background: '#fff', margin: 10, padding: 20 }}>
          <Form.Item label="Course Title">
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'Please input course title!' }],
            })(<Input placeholder="Course Title" />,
            )}
          </Form.Item>
          <Form.Item label="Course Code">
            {getFieldDecorator('code', {
              rules: [{ required: true, message: 'Please input course code!' }],
            })(<Input placeholder="Course Code" />,
            )}
          </Form.Item>
          <Form.Item label="Pre-Requisite">
            {getFieldDecorator('pre_req', { initialValue: 'Nil' })
            (<Input placeholder="Pre-Requisite" />,
            )}
          </Form.Item>
          <Form.Item label="Co-Requisite">
            {getFieldDecorator('co_req', { initialValue: 'Nil' })
            (<Input placeholder="Course Code" />,
            )}
          </Form.Item>
          <Form.Item label="Number of Units">
            {getFieldDecorator('unit', {
              rules: [{ required: true, type: 'number', message: 'Please input unit of the course!' }],
            })(<InputNumber placeholder="Units" min={1} max={1000} />,
            )}
          </Form.Item>
          <Form.Item label="Contact Hours">
            {getFieldDecorator('hours', {
              rules: [{ required: true, type: 'number', message: 'Please input contact hours!' }],
            })(<InputNumber placeholder="Hours" min={1} max={10000} />,
            )}
          </Form.Item>
          <Form.Item label="Offering Unit">
            {getFieldDecorator('offer_unit', {
              rules: [{ required: true, message: 'Please input the department which provide the unit!' }],
            })(<Input placeholder="Offering Unit" />,
            )}
          </Form.Item>
          <Form.Item label="Syllabus Prepared & Reviewed by">
            {getFieldDecorator('prepare', {
              rules: [{ required: true, message: 'Please input people who prepared the document!' }],
            })(<Input placeholder="Prepared by" />,
            )}
            {getFieldDecorator('review', {
              rules: [{ required: true, message: 'Please input people who reviewed the document!' }],
            })(<Input placeholder="Reviewed by" />,
            )}
          </Form.Item>
          <Form.Item label="Aims & Objectives">
            {getFieldDecorator('aim', {
              rules: [{ required: true, message: 'Please input aims and objectives of the course!' }],
            })(<TextArea autosize placeholder="Aims & Objectives" />,
            )}
          </Form.Item>
          {contentItems}
          <Form.Item>
            <Button type="dashed" onClick={() => this.add('contents')}
                    style={{ marginLeft: '10%', width: '80%' }}>
              <Icon type="plus" /> Add Course Content
            </Button>
          </Form.Item>
          {piloItems}
          <Form.Item>
            <Button type="dashed" onClick={() => this.add('pilos')}
                    style={{ marginLeft: '10%', width: '80%' }}>
              <Icon type="plus" /> Add PILO Content
            </Button>
          </Form.Item>
          {ciloItems}
          <Form.Item>
            <Button type="dashed" onClick={() => this.add('cilos')}
                    style={{ marginLeft: '10%', width: '80%' }}>
              <Icon type="plus" /> Add CILO Content
            </Button>
          </Form.Item>
          <Text strong>TLAs</Text>
          {CILO.map((k, index) => {
              return (<Form.Item label={"CILO" + (index + 1)}>
                {getFieldDecorator(`tla[${k}].content`, {
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input TLA of the CILO.",
                    },
                  ],
                })(<TextArea autosize placeholder="TLA Content" />)}
              </Form.Item>)
            }
          )}
          {assessmentItems}
          <Form.Item>
            <Button type="dashed" onClick={() => this.add('assessments')}
                    style={{ marginLeft: '10%', width: '80%' }}>
              <Icon type="plus" /> Add Assessment Methods
            </Button>
          </Form.Item>
          {textbookItems}
          <Form.Item>
            <Button type="dashed" onClick={() => this.add('textBooks')}
                    style={{ marginLeft: '10%', width: '80%' }}>
              <Icon type="plus" /> Add Textbook Information
            </Button>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" style={{ width: 100, marginRight: 10 }} loading={loading}>
              Submit
            </Button>
            <Button htmlType="button" style={{ width: 100 }} onClick={() => window.history.go(-1)}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
        {redirect}
      </div>
    );
  }
}

export const WrappedNewSyllabusForm = Form.create({ name: 'new_syllabus' })(NewSyllabusForm);
