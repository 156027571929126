import React from "react";
import { Input, Table, Icon, Divider, Tooltip, Button, Row, Skeleton, Breadcrumb, message } from "antd";
import { Link } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2';

const { Search } = Input;
const InputGroup = Input.Group;

const search_word = (keyword, origin) => {
  return origin.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
};

export class AuditSchool extends React.Component {
  state = {
    disableDelete: true,
    selectedRowKeys: [],
    data: [],
    loading: true,
    results: []
  };

  constructor(...props) {
    super(...props);
    axios({
      method: 'get',
      url: '/api/audits'
    }).then(rsp => {
      this.setState({
        data: rsp.data.audits,
        results: rsp.data.audits,
        loading: false
      });
    });
  }

  approveItems = () => {
    const { selectedRowKeys } = this.state;
    for (let i of selectedRowKeys) {
      axios({
        method: 'put',
        url: '/api/audit',
        params: {
          id: i
        }
      });
    }
  };

  declineItems = () => {
    const { selectedRowKeys } = this.state;
    for (let i of selectedRowKeys) {
      axios({
        method: 'delete',
        url: '/api/audit',
        params: {
          id: i
        }
      });
    }
  };

  onSelectChange = selectedRowKeys => {
    this.setState({
      selectedRowKeys,
      disableDelete: selectedRowKeys.length === 0
    });
  };

  handleChange = e => {
    const { data } = this.state;
    let keyword = e.target.value;
    if (keyword === "")
      this.setState({
        results: data
      });
    this.setState({
      keyword
    });
  };

  handleSearch = e => {
    const { data, keyword } = this.state;
    if (keyword.length === 0)
      return;
    let results = [];
    data.forEach(item => {
      if (search_word(keyword, item.school_name))
        results.push(item);
    });
    this.setState({
      results
    });
  };

  approveOne = id => {
    let data = this.state.data;
    data.forEach((item, index) => {
      if (item.id === id) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You are trying to approve ' + item.school_name,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Approve',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return axios({
              method: 'put',
              url: '/api/audit',
              params: {
                id: id
              }
            });
          }
        }).then(result => {
          if (result.value) {
            data.splice(index, 1);
            this.setState({
              data,
              results: data,
              disableDelete: true,
              selectedRowKeys: []
            });
            message.success("Successfully approved " + item.school_name);
            if (data.length === 0)
              setTimeout(() => window.location.reload(), 1000);
          }
        });
      }
    });
  };

  declineOne = id => {
    let data = this.state.data;
    data.forEach((item, index) => {
      if (item.id === id) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You are trying to decline ' + item.school_name,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          confirmButtonText: 'Decline',
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return axios({
              method: 'delete',
              url: '/api/audit',
              params: {
                id: id
              }
            });
          }
        }).then(result => {
          if (result.value) {
            data.splice(index, 1);
            this.setState({
              data,
              results: data,
              disableDelete: true,
              selectedRowKeys: []
            });
            message.success("Successfully declined " + item.school_name);
            if (data.length === 0)
              setTimeout(() => window.location.reload(), 1000);
          }
        });
      }
    });
  };

  render() {
    const { data, disableDelete, selectedRowKeys, loading, results } = this.state;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'school_name',
        render: (text, record) => (
          <a onClick={() => {
            Swal.fire({
              type: 'info',
              title: text,
              html: '<p>Username: ' + record.user_name + '</p>' +
                '<p>Location: ' + record.location + '</p>' +
                '<p>Introduction: ' + record.introduction + '</p>',
              footer: '<a href="/static/application/' + record.description +'">Download description file</a>'
            });
          }}>{text}</a>
        )
      },
      {
        title: 'Location',
        dataIndex: 'location',
      },
      {
        title: 'Action',
        render: (text, record) => (
          <span>
        <Tooltip title="Approve">
          <a onClick={() => this.approveOne(record.id)}><Icon type="check-circle" /></a>
        </Tooltip>
        <Divider type="vertical" />
        <Tooltip title="Decline">
          <a onClick={() => this.declineOne(record.id)}><Icon type="close-circle" /></a>
        </Tooltip>
      </span>
        )
      }
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const selectedWords = () => {
      let char = [];
      data.forEach(item => {
        if (selectedRowKeys.indexOf(item.id) !== -1)
          char.push(item.school_name + '(' + item.location + ')');
      });
      return char;
    };

    return (
      <Skeleton active loading={loading}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/management">Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/management/school">School Management</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>School Audit</Breadcrumb.Item>
        </Breadcrumb>
        <InputGroup compact>
          <Search
            placeholder="Search"
            onSearch={this.handleSearch}
            onChange={this.handleChange}
            allowClear
            style={{ marginTop: 10 }}
          />
        </InputGroup>
        <Row>
          <Button
            type="primary"
            disabled={disableDelete}
            onClick={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: 'You are approving ' + selectedWords(),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Approve',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                  this.approveItems();
                }
              }).then(result => {
                if (result.value) {
                  let data = this.state.data;
                  data = data.filter(item => selectedRowKeys.indexOf(item.id) === -1);
                  this.setState({
                    disableDelete: true,
                    selectedRowKeys: [],
                    data,
                    results: data
                  }, () => {
                    message.success("Successfully approved selected schools", 2);
                    if (data.length === 0)
                      setTimeout(() => window.location.reload(), 1000);
                  });
                }
              });
            }}
          >Approve</Button>
          <Button
            type="danger"
            disabled={disableDelete}
            style={{ marginTop: 20, marginLeft: 10 }}
            onClick={() => {
              Swal.fire({
                title: 'Are you sure?',
                text: 'You are declining ' + selectedWords(),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Decline',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                  this.declineItems();
                }
              }).then(result => {
                if (result.value) {
                  let data = this.state.data;
                  data = data.filter(item => selectedRowKeys.indexOf(item.id) === -1);
                  this.setState({
                    disableDelete: true,
                    selectedRowKeys: [],
                    data,
                    results: data
                  }, () => {
                    message.success("Successfully declined selected schools", 2);
                    if (data.length === 0)
                      setTimeout(() => window.location.reload(), 1000);
                  });
                }
              });
            }}
          >
            Decline
          </Button>
        </Row>
        <Table columns={columns} dataSource={results} rowSelection={rowSelection}
               rowKey='id' style={{ background: '#fff', marginTop: 20, overflowX: "auto" }} />
      </Skeleton>
    )
  }
}
