import { Form, Input, Tooltip, Icon, Button, Typography, message, Skeleton, Select, Upload } from 'antd';
import React from 'react';
import { Redirect } from "react-router-dom";
import axios from 'axios';
import { UserInfo } from "../Functions";
import ImgCrop from "antd-img-crop";

const { Title } = Typography;
const { Option } = Select;

class EditProfileForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    user: {
      username: null,
      role_id: 4,
      email: null,
      school: {
        name: null
      }
    },
    loading: true,
    schools: [],
    redirect: null,
    btnLoading: false,
    fileList: []
  };

  constructor(...props) {
    super(...props);
    const userInfo = new UserInfo();
    axios({
      method: 'get',
      url: '/api/schools'
    }).then(rsp => {
      this.setState({
        schools: rsp.data.schools
      });
      axios({
        method: 'post',
        url: '/api/token',
        data: {
          token: userInfo.getUserInfo().token
        }
      }).then(rsp => {
        if (rsp.data.user) {
          let user = {
            username: rsp.data.user.name,
            school: rsp.data.user.school,
            role_id: rsp.data.user.role.id,
            email: rsp.data.user.email
          };
          if (!user.school) {
            user.school = {
              name: null
            }
          }
          this.setState({
            user,
            loading: false
          });
        }
      });
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          btnLoading: true
        });
        let token = new UserInfo().getUserInfo().token;
        axios({
          method: 'post',
          url: '/api/user/update',
          data: {
            token: token,
            email: values.email
          }
        }).then(rsp => {
          if (rsp.data.status) {
            message.success("User profile updated successfully!");
            this.setState({
              btnLoading: false,
              redirect: <Redirect to='/profile' />
            });
          }
          else {
            message.error("Error when updating user profile.");
            this.setState({
              btnLoading: false
            });
          }
        });

      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, user, schools, redirect, btnLoading, fileList } = this.state;
    const schoolOptions = schools.map(item => (
      <Option value={item.name}>{item.name}</Option>
    ));
    const userInfo = new UserInfo();

    return (
      <div style={{ background: "#fff", padding: 20 }}>
        <Skeleton active loading={loading}>
          <Title level={3}>Edit Profile</Title>
          {redirect}
          <Form onSubmit={this.handleSubmit}>
            <Form.Item label='Upload'>
              <ImgCrop>
              <Upload
                name="logo"
                customRequest={options => {
                  const { onSuccess, file, onProgress } = options;
                  let formData = new FormData();
                  formData.append("file", file);
                  formData.append("token", userInfo.getUserInfo().token);
                  axios({
                    method: 'post',
                    url: '/api/user/avatar/upload',
                    data: formData,
                    headers: { 'Content-Type': 'multipart/form-data' },
                    onUploadProgress: e => {
                      onProgress({ percent: (e.loaded / e.total) * 100 });
                    }
                  }).then(rsp => {
                    let filename = rsp.data.name;
                    this.setState({
                      filename
                    });
                    onSuccess(rsp.data);
                  });
                }}
                fileList={fileList}
                listType="picture"
                onChange={info => {
                  let fileList = [...info.fileList];
                  fileList = fileList.slice(-1);

                  fileList = fileList.map(file => {
                    if (file.response) {
                      // Component will show file.url as link
                      file.url = file.response.url;
                    }
                    return file;
                  });

                  this.setState({ fileList });
                }}
              >
                <Button>
                  <Icon type="upload" /> Upload Avatar
                </Button>
              </Upload>
              </ImgCrop>
            </Form.Item>
            <Form.Item
              label={
                <span>
              Username&nbsp;
                  <Tooltip title="What do you want others to call you?">
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
              }
            >
              {getFieldDecorator('username', {
                rules: [{ required: true, message: 'Please input your nickname!', whitespace: true }],
                initialValue: user.username
              })(<Input disabled />)}
            </Form.Item>
            <Form.Item label="E-mail">
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ],
                initialValue: user.email
              })(<Input />)}
            </Form.Item>
            <Form.Item label="School">
              {getFieldDecorator('school', {
                initialValue: user.school.name
              })(<Select disabled>
                {schoolOptions}
              </Select>)}
            </Form.Item>
            <Button type="primary" htmlType="submit" loading={btnLoading}>
              Update
            </Button>
            <Button onClick={() => window.history.back()} style={{ marginLeft: 20 }}>Cancel</Button>
          </Form>
        </Skeleton>
      </div>
    );
  }
}

export const WrappedEditProfileForm = Form.create({ name: 'edit_profile' })(EditProfileForm);
