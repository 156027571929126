import { Card, Row, Col, Icon, Typography, Skeleton, Divider, Tooltip, message } from 'antd';
import React from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import Clipboard from 'react-clipboard.js';
import { UserInfo } from "./Functions";

const { Title } = Typography;

/* Custom Components */

/**
 * Title of information card section
 * @param props
 * @property title: Title of section
 * @property href: Link to View All
 */
const SectionTitle = (props) => {
  return (
    <Row style={{ marginTop: 30 }}>
      <Col lg={3} md={5} xs={12} offset={1}>
        <Title level={2}>{props.title}</Title>
      </Col>
      <Col lg={{ span: 4, offset: 1 }} sm={3} xs={{ span: 12, offset: 1 }}
           style={{ paddingTop: 10, marginBottom: 20 }}>
        <Link to={props.href}>View All></Link>
      </Col>
    </Row>
  )
};

/**
 * Information Card
 * @param props
 * @property title: Title of Information Card
 * @property info: Information of card
 * @property href: Redirect location
 */
const InfoCard = (props) => {
  return (
    <Col xs={24} md={12} lg={8} xxl={6}>
        <Card
          title={<Link to={props.href}>{props.title}</Link>}
          style={{ width: "80%", marginLeft: "10%", marginBottom: 20 }}
          extra={props.extra}
        >
          <Link to={props.href} style={{ color: '#000' }}>
            {props.info}
          </Link>
        </Card>
    </Col>
  )
};

export class Home extends React.Component {
  state = {
    courses: [],
    schools: [],
    loading: {
      main: true,
      courses: new Array(6).fill(false),
      schools: new Array(6).fill(false)
    },
    favorite: {
      courses: [],
      schools: []
    }
  };

  constructor(...props) {
    super(...props);
    const userInfo = new UserInfo();
    axios({
      method: 'get',
      url: '/api/schools'
    }).then(rsp => {
      let schools = rsp.data.schools;
      let display = [];
      if (schools.length <= 6) {
        display = schools;
      }
      else {
        for (let i = 0; i < 6; i++) {
          let num = Math.floor(Math.random() * schools.length);
          display.push(schools[num]);
          schools.splice(num, 1);
        }
      }
      this.setState({
        schools: display,
      });
    });
    axios({
      method: 'get',
      url: '/api/syllabuses'
    }).then(rsp => {
      if (userInfo.getUserInfo()) {
        axios({
          method: 'post',
          url: '/api/token',
          data: {
            token: userInfo.getUserInfo().token
          }
        }).then(response => {
          let user_id = response.data.user.id;
          axios({
            method: 'get',
            url: '/api/favorite/school',
            params: {
              user_id: user_id
            }
          }).then(syllabus => {
            let favorite = this.state.favorite;
            favorite.schools = syllabus.data.schools;
            this.setState({
              favorite
            });
          });
          axios({
            method: 'get',
            url: '/api/favorite/syllabus',
            params: {
              user_id: user_id
            }
          }).then(syllabus => {
            let loading = this.state.loading;
            let favorite = this.state.favorite;
            favorite.courses = syllabus.data.syllabuses;
            loading.main = false;
            this.setState({
              favorite,
              loading
            });
          });
        });
      }
      else {
        let loading = this.state.loading;
        loading.main = false;
        this.setState({
          loading
        });
      }
      let courses = rsp.data.syllabuses;
      let display = [];
      if (courses.length <= 6) {
        display = courses;
      }
      else {
        for (let i = 0; i < 6; i++) {
          let num = Math.floor(Math.random() * courses.length);
          display.push(courses[num]);
          courses.splice(num, 1);
        }
      }
      this.setState({
        courses: display
      });
    });
  }

  render() {
    const { courses, schools, loading } = this.state;
    const userInfo = new UserInfo();

    return (
      <div>
        <Skeleton active loading={loading.main}>
        <SectionTitle href="/search" title="Course" />
        <Row>
          {courses.map((c, index) => (
            <InfoCard
              title={c.title}
              key={c.title}
              href={"/course/detail/" + c.id}
              info={<div>
                <p><Icon type="bank" /> {c.school.name}</p>
                <p><Icon type="environment" /> {c.school.location}</p>
              </div>}
              extra={[
                <Tooltip title={userInfo.checkFavorite(this.state.favorite.courses, c.id) ? 'Remove from favorite' : 'Add to favorite'}>
                  <a onClick={() => {
                    let load = this.state.loading;
                    if (!userInfo.getUserInfo()) {
                      message.warning("Please login to add to favorite!");
                      return;
                    }
                    load.courses[index] = true;
                    this.setState({
                      loading: load
                    });
                    axios({
                      method: 'post',
                      url: '/api/favorite',
                      data: {
                        token: userInfo.getUserInfo().token,
                        syllabus_id: c.id,
                        school_id: 0
                      }
                    }).then(rsp => {
                      let favorite = this.state.favorite;
                      if (rsp.data.status) {
                        favorite.courses.push(c);
                        message.success("Added " + c.title
                          + "(" + c.school.name + ') to favorite!');
                        load.courses[index] = false;
                        this.setState({
                          favorite,
                          loading: load
                        });
                      }
                      else {
                        axios({
                          method: 'delete',
                          url: '/api/favorite',
                          data: {
                            token: userInfo.getUserInfo().token,
                            syllabus_id: c.id,
                            school_id: 0
                          }
                        }).then(response => {
                          favorite.courses.forEach((item, index) => {
                            if (item.id === c.id) {
                              favorite.courses.splice(index, 1);
                            }
                          });
                          load.courses[index] = false;
                          this.setState({
                            favorite,
                            loading: load
                          });
                          message.warning("You have removed this syllabus from favorite");
                        });
                      }
                    });
                  }}>
                    {loading.courses[index] ? <Icon type='loading' /> :
                      <Icon type='star' theme={userInfo.checkFavorite(this.state.favorite.courses, c.id) ? 'filled' : 'outlined'} />}
                  </a>
                </Tooltip>,
                <Divider type="vertical" />,
                <Clipboard
                  data-clipboard-text={"https://syllabus.drjchn.com/course/detail/" + c.id}
                  component="a"
                  onSuccess={() => message.success("Course detail link has copied!")}
                >
                  <Tooltip title='Copy Link'><Icon type='copy' /></Tooltip>
                </Clipboard>
              ]}
            />
          ))}
        </Row>
        <SectionTitle href="/school" title="School" />
        <Row>
          {schools.map((c, index) => (
            <InfoCard
              title={c.name}
              key={c.name}
              href={{
                pathname: '/school/detail/' + c.id,
                state: {
                  referrer: this.props.location.pathname
                }
              }}
              info={<div>
                <p><Icon type="file-text" /> {c.syllabuses.length} syllabuses</p>
                <p><Icon type="environment" /> {c.location}</p>
              </div>}
              extra={[
                <Tooltip title={userInfo.checkFavorite(this.state.favorite.schools, c.id) ? 'Remove from favorite' : 'Add to favorite'}>
                  <a onClick={() => {
                    let load = this.state.loading;
                    if (!userInfo.getUserInfo()) {
                      message.warning("Please login to add to favorite!");
                      return;
                    }
                    load.schools[index] = true;
                    this.setState({
                      loading: load
                    });
                    axios({
                      method: 'post',
                      url: '/api/favorite',
                      data: {
                        token: userInfo.getUserInfo().token,
                        syllabus_id: 0,
                        school_id: c.id
                      }
                    }).then(rsp => {
                      let favorite = this.state.favorite;
                      if (rsp.data.status) {
                        favorite.schools.push(c);
                        message.success("Added " + c.name
                          + "(" + c.location + ') to favorite!');
                        load.schools[index] = false;
                        this.setState({
                          favorite,
                          loading: load
                        });
                      }
                      else {
                        favorite.schools.forEach((item, index) => {
                          if (item.id === c.id) {
                            favorite.schools.splice(index, 1);
                          }
                        });
                        axios({
                          method: 'delete',
                          url: '/api/favorite',
                          data: {
                            token: userInfo.getUserInfo().token,
                            syllabus_id: 0,
                            school_id: c.id
                          }
                        }).then(response => {
                          load.schools[index] = false;
                          this.setState({
                            favorite,
                            loading: load
                          });
                          message.warning("You have removed this school from favorite");
                        });
                      }
                    });
                  }}>
                    {loading.schools[index] ? <Icon type='loading' /> :
                      <Icon type='star' theme={userInfo.checkFavorite(this.state.favorite.schools, c.id) ? 'filled' : 'outlined'} />}
                  </a>
                </Tooltip>,
                <Divider type="vertical" />,
                <Clipboard
                  data-clipboard-text={"https://syllabus.drjchn.com/school/detail/" + c.id}
                  component="a"
                  onSuccess={() => message.success("School detail link has copied!")}
                >
                  <Tooltip title='Copy Link'><Icon type='copy' /></Tooltip>
                </Clipboard>
              ]}
            />
          ))}
        </Row>
      </Skeleton>
      </div>
    );
  }
}
