import React from 'react';
import { Typography, Button, Icon, Tooltip, Breadcrumb, Table, Skeleton, message } from "antd";
import { Link } from "react-router-dom";
import axios from 'axios';
import Clipboard from 'react-clipboard.js';
import { UserInfo } from "../Functions";

const { Title, Paragraph, Text } = Typography;

export class CourseDetail extends React.Component {
  state = {
    data: {
      school: {},
      contents: [],
      pilos: []
    },
    favorite: false,
    loading: [true, false]
  };

  getPiloById = id => {
    const { data } = this.state;
    for (let item of data.pilos) {
      if (item.id === id) {
        return item.pilo_id;
      }
    }
  };

  getCiloById = id => {
    const { data } = this.state;
    for (let item of data.cilos) {
      if (item.id === id) {
        return item.cilo_id;
      }
    }
  };

  switchView = (pathname, course) => {
    let path = pathname.split('/')[1];
    switch (path) {
      case 'school':
        return (<Breadcrumb.Item><Link to={{
          pathname: '/school/detail/' + course.school.id,
          state: {
            referrer: this.props.location.pathname
          }
        }}>{course.school.name}</Link></Breadcrumb.Item>);
      case 'course':
        return (<Breadcrumb.Item><Link to={'/course/index/' + course.title}>{course.title}</Link></Breadcrumb.Item>);
      case 'profile':
        return (<Breadcrumb.Item><Link to='/profile'>Profile</Link></Breadcrumb.Item>);
      case 'management':
        return (<span>
          <Breadcrumb.Item><Link to='/management'>Management</Link></Breadcrumb.Item>
          <Breadcrumb.Item><Link to='/management/syllabus'>Syllabus Management</Link></Breadcrumb.Item>
        </span>);
      default:
        return (<Breadcrumb.Item><Link to='/search'>Search Syllabus</Link></Breadcrumb.Item>);
    }
  };

  constructor(...props) {
    super(...props);
    const course_id = this.props.match.params.id;
    let loading = this.state.loading;
    const userInfo = new UserInfo();
    axios({
      method: 'get',
      url: '/api/syllabus',
      params: {
        id: course_id
      }
    }).then(rsp => {
      this.setState({
        data: rsp.data.syllabus,
      });
      if (userInfo.getUserInfo()) {
        axios({
          method: 'post',
          url: '/api/token',
          data: {
            token: userInfo.getUserInfo().token
          }
        }).then(resp => {
          axios({
            method: 'get',
            url: '/api/favorite/syllabus',
            params: {
              user_id: resp.data.user.id
            }
          }).then(response => {
            loading[0] = false;
            this.setState({
              favorite: userInfo.checkFavorite(response.data.syllabuses, this.state.data.id),
              loading
            });
          });
        });
      }
      else {
        loading[0] = false;
        this.setState({
          loading
        })
      }
    });
  }

  render() {
    const { data, loading, favorite } = this.state;
    const userInfo = new UserInfo();
    const columns = {
      pilos: [
        {
          title: 'PILO',
          dataIndex: 'pilo_id',
          render: text => 'PILO' + text
        },
        {
          title: 'PILO Content',
          dataIndex: 'content',
        }
      ],
      cilos: [
        {
          title: 'CILO',
          dataIndex: 'cilo_id',
          render: text => 'CILO' + text
        },
        {
          title: 'Content',
          dataIndex: 'content',
        },
        {
          title: 'PILO(s) to be addressed',
          dataIndex: 'pilo_cilos',
          render: arr => (
            arr.map(item => (<Text key={this.getPiloById(item.pilo_id)}>PILO{this.getPiloById(item.pilo_id)} </Text>))
          )
        }
      ],
      tlas: [
        {
          title: 'CILO No.',
          dataIndex: 'cilo_id',
          render: text => 'CILO' + text
        },
        {
          title: 'TLAs',
          dataIndex: 'tla.content'
        }
      ],
      assessments: [
        {
          title: 'Assessment Method',
          dataIndex: 'method'
        },
        {
          title: "Weighting",
          dataIndex: "weighting",
          render: text => text + "%"
        },
        {
          title: "CILOs to be addressed",
          dataIndex: "assessment_cilos",
          render: arr => (
            arr.map(item => (<Text key={this.getCiloById(item.cilo_id)}>CILO{this.getCiloById(item.cilo_id)} </Text>))
          )
        },
        {
          title: "Description",
          dataIndex: "description"
        }
      ],
      textBooks: [
        {
          title: "Title",
          dataIndex: "title"
        },
        {
          title: "Publish Year",
          dataIndex: "year"
        },
        {
          title: "Author(s)",
          dataIndex: "author"
        }
      ]
    };

    return (
      <div style={{ background: '#fff', padding: 20 }}>
        <Skeleton active loading={loading[0]}>
          <Breadcrumb>
            <Breadcrumb.Item><Link to='/'>Home</Link></Breadcrumb.Item>
            {this.props.location.state && this.switchView(this.props.location.state.referrer, data)}
            <Breadcrumb.Item>{data.title}, {data.school.name}</Breadcrumb.Item>
          </Breadcrumb>
          <Title>{data.code} {data.title}, <Link to={{
            pathname: '/school/detail/' + data.school.id,
            state: {
              referrer: this.props.location.pathname
            }
          }}>{data.school.name}</Link></Title>
          <div style={{ float: "right" }}>
            <Tooltip title={favorite ? 'Remove from favorite' : 'Add to favorite'}>
              <Button
                style={{ marginRight: 10 }}
                onClick={() => {
                  let load = this.state.loading;
                  if (!userInfo.getUserInfo()) {
                    message.warning("Please login to add to favorite!");
                    return;
                  }
                  load[1] = true;
                  this.setState({
                    loading: load
                  });
                  axios({
                    method: 'post',
                    url: '/api/favorite',
                    data: {
                      token: userInfo.getUserInfo().token,
                      syllabus_id: data.id,
                      school_id: 0
                    }
                  }).then(rsp => {
                    if (rsp.data.status) {
                      load[1] = false;
                      this.setState({
                        loading: load
                      });
                      message.success("Added " + data.title
                        + "(" + data.school.name + ') to favorite!');
                    } else {
                      axios({
                        method: 'delete',
                        url: '/api/favorite',
                        data: {
                          token: userInfo.getUserInfo().token,
                          syllabus_id: data.id,
                          school_id: 0
                        }
                      }).then(rsp => {
                        load[1] = false;
                        this.setState({
                          loading: load
                        });
                        message.warning("You have removed this syllabus from favorite");
                      });
                    }
                    this.setState({
                      favorite: !favorite
                    });
                  });
                }}
              >
                {loading[1] ? <Icon type='loading' /> : <Icon type="star" theme={favorite ? 'filled' : 'outlined'} />}
              </Button>
            </Tooltip>
            <Clipboard
              data-clipboard-text={"https://syllabus.drjchn.com/course/detail/" + data.id}
              component="a"
              onSuccess={() => message.success("Course detail link has copied!")}
            >
              <Tooltip title="Copy Link">
                <Button><Icon type="copy" /></Button>
              </Tooltip>
            </Clipboard>
          </div>
          <Title level={3}>Unit</Title>
          <Paragraph>{data.unit}</Paragraph>
          <Title level={3}>Contact Hours</Title>
          <Paragraph>{data.hours}</Paragraph>
          <Title level={3}>Offer Unit</Title>
          <Paragraph>{data.offer_unit}</Paragraph>
          <Title level={3}>Pre-Requisition</Title>
          <Paragraph>{data.pre_req}</Paragraph>
          <Title level={3}>Co-Requisition</Title>
          <Paragraph>{data.co_req}</Paragraph>
          <Title level={3}>Prepared by</Title>
          <Paragraph>{data.prepare}</Paragraph>
          <Title level={3}>Reviewed by</Title>
          <Paragraph>{data.review}</Paragraph>
          <Title level={3}>Aims & Objectives</Title>
          <Paragraph>{data.aim}</Paragraph>
          <Title level={3}>Course Contents</Title>
          {data['contents'].map(content => (
            <div key={content.content_id}>
              <Title level={4}>{content.content_id}. {content.title}</Title>
              <Text mark><Icon type="clock-circle" /> {content.hours} hrs</Text>
              <Paragraph>{content.content}</Paragraph>
            </div>
          ))}
          <Title level={3}>Programme Intended Learning Outcomes(PILOs)</Title>
          <Table columns={columns['pilos']}
                 dataSource={data['pilos']} rowKey='pilo_id' />
          <Title level={3}>CILOs-PILOs Mapping Matrix</Title>
          <Table columns={columns['cilos']}
                 dataSource={data['cilos']} rowKey='id' />
          <Title level={3}>Teaching & Learning Activities</Title>
          <Table columns={columns['tlas']}
                 dataSource={data['cilos']} rowKey='id' />
          <Title level={3}>Assessment Methods</Title>
          <Table
            columns={columns['assessments']}
            dataSource={data['assessments']}
            style={{ overflowX: 'auto' }}
            rowKey='method'
          />
          <Title level={3}>Textbooks/Recommended Readings</Title>
          <Table columns={columns['textBooks']}
                 dataSource={data['textBooks']} rowKey='id' />
        </Skeleton>
      </div>
    );
  }
}
