import React from "react";
import { Input, Table, Icon, Tooltip, Breadcrumb, Button, Row, Skeleton, message, Divider } from "antd";
import { Link } from "react-router-dom";
import axios from 'axios';
import Clipboard from 'react-clipboard.js';
import { UserInfo } from "../Functions";

const search_word = (keyword, origin) => {
  return origin.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
};

export class SearchPage extends React.Component {
  state = {
    data: [],
    keyword: {
      course: '',
      school: ''
    },
    results: [],
    loading: true
  };

  constructor(...props) {
    super(...props);
    const userInfo = new UserInfo();
    axios({
      method: 'get',
      url: '/api/syllabuses'
    }).then(rsp => {
      let data = rsp.data.syllabuses;
      data.forEach(item => {
        item.loading = false;
        item.favorite = false;
      });
      if (userInfo.getUserInfo()) {
        axios({
          method: 'post',
          url: '/api/token',
          data: {
            token: userInfo.getUserInfo().token
          }
        }).then(resp => {
          axios({
            method: 'get',
            url: '/api/favorite/syllabus',
            params: {
              user_id: resp.data.user.id
            }
          }).then(syllabus => {
            let favorite = syllabus.data.syllabuses;
            data.forEach(item => {
              item.favorite = userInfo.checkFavorite(favorite, item.id);
            });
            this.setState({
              favorite,
              data,
              results: data,
              loading: false
            });
          });
        });
      }
      else {
        this.setState({
          data,
          results: data,
          loading: false
        });
      }
    });
  }

  handleSearch = e => {
    const { data, keyword } = this.state;
    if (keyword['course'].length === 0 && keyword['school'].length === 0)
      return;
    let results = [];
    data.forEach(item => {
      if (keyword['school'].length === 0) {
        if (search_word(keyword['course'], item.title))
          results.push(item);
      } else if (keyword['course'].length === 0) {
        if (search_word(keyword['school'], item.school.name))
          results.push(item);
      } else {
        if (search_word(keyword['course'], item.title) && search_word(keyword['school'], item.school.name))
          results.push(item);
      }
    });
    this.setState({
      results
    });
  };

  handleClear = e => {
    const { data } = this.state;
    let keyword = this.state.keyword;
    keyword['course'] = '';
    keyword['school'] = '';
    this.setState({
      keyword,
      results: data
    });
  };

  handleChange = (e, category) => {
    let keyword = this.state.keyword;
    keyword[category] = e.target.value;
    this.setState({
      keyword
    });
  };

  render() {
    const { results, keyword, loading } = this.state;
    const userInfo = new UserInfo();
    const columns = [
      {
        title: 'Name',
        dataIndex: 'title',
        render: (text, record) => (<Link to={{
          pathname: '/course/detail/' + record.id,
          state: {
            referrer: this.props.location.pathname
          }
        }}>{text}</Link>)
      },
      {
        title: 'School',
        dataIndex: 'school.name',
      },
      {
        title: 'Action',
        render: (text, record) => (
          <div>
            <Tooltip title={record.favorite ? 'Remove from favorite' : 'Add to favorite'}>
              <a onClick={() => {
                let res = results;
                let data = this.state.data;
                if (!userInfo.getUserInfo()) {
                  message.warning("Please login to add to favorite!");
                  return;
                }
                res.forEach(item => {
                  if (item.id === record.id) {
                    item.loading = true;
                  }
                });
                this.setState({
                  results: res
                });
                axios({
                  method: 'post',
                  url: '/api/favorite',
                  data: {
                    token: userInfo.getUserInfo().token,
                    syllabus_id: record.id,
                    school_id: 0
                  }
                }).then(rsp => {
                  let favorite = this.state.favorite;
                  if (rsp.data.status) {
                    favorite.push(record);
                    message.success("Added " + record.title
                      + "(" + record.school.name + ') to favorite!');
                    res.forEach(item => {
                      if (item.id === record.id) {
                        item.loading = false;
                        item.favorite = true;
                      }
                    });
                    data.forEach(item => {
                      if (item.id === record.id) {
                        item.favorite = true;
                      }
                    });
                    this.setState({
                      favorite,
                      results: res,
                      data
                    });
                  } else {
                    axios({
                      method: 'delete',
                      url: '/api/favorite',
                      data: {
                        token: userInfo.getUserInfo().token,
                        syllabus_id: record.id,
                        school_id: 0
                      }
                    }).then(response => {
                      favorite.forEach((item, index) => {
                        if (item.id === record.id) {
                          favorite.splice(index, 1);
                        }
                      });
                      res.forEach(item => {
                        if (item.id === record.id) {
                          item.favorite = false;
                          item.loading = false;
                        }
                      });
                      data.forEach(item => {
                        if (item.id === record.id) {
                          item.favorite = false;
                        }
                      });
                      this.setState({
                        favorite,
                        results: res,
                        data
                      });
                      message.success("You have removed this syllabus from favorite");
                    });
                  }
                });
              }}>
                {record.loading ? <Icon type='loading' /> :
                  <Icon type="star" theme={record.favorite ? 'filled' : 'outlined'} />}
              </a>
            </Tooltip>
            <Divider type='vertical' />
            <Clipboard
              data-clipboard-text={"https://syllabus.drjchn.com/course/detail/" + record.id}
              component="a"
              onSuccess={() => message.success("Course detail link has copied!")}
            >
              <Tooltip title="Copy Link">
                <Icon type="copy" />
              </Tooltip>
            </Clipboard>
          </div>
        )
      }
    ];

    return (
      <Skeleton active loading={loading}>
        <Breadcrumb>
          <Breadcrumb.Item><Link to='/'>Home</Link></Breadcrumb.Item>
          <Breadcrumb.Item>Search</Breadcrumb.Item>
        </Breadcrumb>
        <Input
          placeholder="Course"
          allowClear
          style={{ marginTop: 10 }}
          value={keyword['course']}
          onChange={e => this.handleChange(e, 'course')}
          onKeyDown={e => {
            if (e.keyCode === 13) this.handleSearch();
          }}
        />
        <Input
          placeholder="School"
          allowClear
          style={{ marginTop: 10 }}
          value={keyword['school']}
          onChange={e => this.handleChange(e, 'school')}
          onKeyDown={e => {
            if (e.keyCode === 13) this.handleSearch();
          }}
        />
        <Row style={{ marginTop: 10, marginRight: 10 }}>
          <Button
            type='primary'
            style={{ float: 'right' }}
            onClick={this.handleSearch}
          >Search</Button>
          <Button
            style={{ float: 'right', marginRight: 20 }}
            onClick={this.handleClear}
          >Clear</Button>
        </Row>
        <Table columns={columns} dataSource={results}
               rowKey='id' style={{ background: '#fff', marginTop: 20 }} />
      </Skeleton>
    )
  }
}
