import { Form, Input, Button, Typography, message } from 'antd';
import React from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";

const { Title } = Typography;


class ForgetPasswordForm extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    redirect: null,
    loading: false
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        axios({
          method: 'post',
          url: '/api/password/mail',
          data: {
            email: values.email
          }
        }).then(rsp => {
          if (rsp.data.status) {
            message.success("Successfully send email. Please go to your mailbox to check");
            this.setState({
              loading: false,
              redirect: <Redirect to='/' />
            });
          }
          else {
            message.error("Wrong email address.");
            this.setState({
              loading: false
            })
          }
        });
      }
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { redirect, loading } = this.state;

    return (
      <div style={{ background: "#fff", padding: 20 }}>
        <Title level={3}>Forget Password</Title>
        {redirect}
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Email" hasFeedback>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your email address!',
                }
              ],
            })(<Input />)}
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Send
          </Button>
          <Button onClick={() => window.history.back()} style={{ marginLeft: 20 }}>Cancel</Button>
        </Form>
      </div>
    );
  }
}

export const WrappedForgetPasswordForm = Form.create({ name: 'forget_password' })(ForgetPasswordForm);
